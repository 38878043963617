import React, { useState } from 'react'
import { Avatar, Button, CardHeader, Grid, Paper, TextField, Typography } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { AuthApi } from '../services/apirest';
import Logo from '../img/logoNetizar.png';
import Image from '../img/farma.png'
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import { isMobile } from 'react-device-detect';
import { logEventos } from '../services/services';


export default function Login() {
    let navigate = useNavigate();

    const sistema = "Farmacia"

    const paperStyle = { padding: 15, height: '50vh', width: 220, marginTop: "50px", marginLeft: "20px", backgroundColor: "#e8dfe1", opacity: 0.8 }
    const avatarStyle = { backgroundColor: '#969fe3' }
    const btnstyle = { margin: '8px 0' }

    const [mensaje, setMensaje] = useState("")
    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const guardarlocal = (response) => {
        //console.log(response)
        localStorage.setItem("token", response.token)
        localStorage.setItem("user", response.user.username)
        localStorage.setItem("id_legajo", response.user.id_legajo)
        localStorage.setItem('token-expiration', Date.now() + 2 * 60 * 60 * 1000)
        localStorage.setItem("role", response.user.role)
        localStorage.setItem("sucursal", response.user.sucursal)
        //localStorage.setItem("sucursalnombre", response.user.sucnombre)
        localStorage.setItem("apellidonombre", response.user.apellido + ", " + response.user.nombrepila)
        ///guarda el evento login del usuario
        logEventos(isMobile ? "Login mobile" : "Login desktop", "Login", "Login", response.user.username)
    }

    const handleEntrar = async (e) => {
        e.preventDefault()
        await fetch(AuthApi + "login", {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(res => res.json())
            .then(data => data.token ?
                (guardarlocal(data), setMensaje(""), navigate("navbar"))
                : setMensaje("Ingreso no autorizado"));
    }

    return (
        <Container sx={{ backgroundImage: `url(${Image})`, backgroundSize: 'cover', }}>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='left'>
                    <CardHeader
                        avatar={<Avatar style={avatarStyle}><LockOutlined /></Avatar>}
                        title={<Typography variant="h5">{sistema}</Typography>}
                    />
                </Grid>
                <form onSubmit={handleEntrar}>
                    <TextField
                        variant="standard"
                        label='Usuario'
                        placeholder='Entre usuario'
                        value={username}
                        onChange={e => setUserName(e.target.value)}
                        fullWidth autoComplete='off' required />
                    <br /><br />
                    <TextField
                        variant="standard"
                        label='Password'
                        placeholder='Entre password'
                        type='password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        fullWidth autoComplete="new-password" required />
                    <br /><br />
                    <Button
                        type='submit'
                        color='primary'
                        variant="outlined"
                        style={btnstyle}
                        fullWidth>
                        Entrar
                    </Button>
                </form>
                {/*isMobile ? "mobile" : "desktop" */}
                <br /><br />
                {<div style={{ color: "red" }}>{mensaje}</div>}
                <br /><br />
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                    <img src={`${Logo}`} style={{ paddingLeft: "5px" }} alt=""></img>
                    <span style={{ paddingLeft: "15px", fontSize: "14pt", fontWeight: "medium", fontFamily: "verdana" }}>Netizar</span>
                </div>
            </Paper>
        </Container>
    )
}