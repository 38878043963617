import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar'
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import { UrlApi } from '../../services/apirest';
import { Box, Container } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';

function Usuarios() {
    let navigate = useNavigate();
    const [users, setUsers] = useState()
    const [openDialog, setOpendialog] = useState(false)
    const [datos, setDatos] = useState([])


    useEffect(() => {
        buscarUsuarios()
    }, [])

    const buscarUsuarios = async () => {
        const data = await fetch(UrlApi + "/userdata/")
        const userData = await data.json()
        setUsers(userData)
    }

    const handleCloseDialog = () => {
        setOpendialog(!openDialog)
    }

    const dialogo = (fila) => {
        setDatos(fila)
        setOpendialog(true)
    }

    const bloquearUsuario = (sino) => {
        ///sino es el parametro recibo SI o No segun este el campo "bloqueado" en "NO" o "SI" espectivamente
        fetch(UrlApi + "/userdata/users/" + datos.id_legajo, {     ///datos.id es el id del usuario a modificar
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ bloqueado: sino })
        })
            .then(buscarUsuarios, setOpendialog(false))
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'username', //access nested data with dot notation
                header: 'Usuario',
                maxSize: 20,
            },
            {
                accessorKey: 'apellido',
                header: 'Apellido',
                size: 50
            },
            {
                accessorKey: 'nombrepila', //normal accessorKey
                header: 'Nombre',
                size: 50
            },
            {
                accessorKey: 'nombreempresa', //normal accessorKey
                header: 'Empresa',
                size: 50
            },
            {
                accessorKey: 'legajo',
                header: 'Legajo',
                size: 10
            },
            {
                accessorKey: 'obrasocial',
                header: 'O.Social',
                size: 10
            },

        ],
        [],
    );

    console.log(users)
    return (
        <div>
            <Navbar />
            {console.log(datos)}
            <Container sx={{ marginTop: '55px' }}>
                <MaterialReactTable
                    data={users ?? []}
                    columns={columns}
                    state={{ showProgressBars: !users ? true : false }}
                    localization={MRT_Localization_ES}
                    enableDensityToggle={false}
                    enableColumnFilters={false}
                    enableTableFooter={false}
                    initialState={{
                        density: 'compact',
                        columnVisibility: { medicamento1: false, medicamento2: false, medicamento3: false, diagnostico: false }
                    }}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: '400px' } }}
                    //enablePagination={false}
                    renderTopToolbarCustomActions={({ table }) => (
                        <>
                            <Box>
                                <IconButton onClick={() => navigate("/usercontrol/nuevo")} color='primary'>
                                    <PersonAddIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="body1" fontSize={24}>
                                Usuarios del sistema
                            </Typography>
                        </>
                    )}
                    //memoMode="rows"
                    // globalFilterFn="any"
                    enableGlobalFilterModes //enable the user to choose between multiple search filter modes
                    globalFilterModeOptions={['fuzzy', 'startsWith']} //only allow the user to choose between fuzzy and startsWith filter modes

                    muiTableBodyCellProps={({ row }) => ({
                        sx: {
                            background:
                                row.original.bloqueado === 'SI' ? "red" : "none",
                            color:
                                row.original.bloqueado === 'SI' ? "white" : "black",
                            borderColor: 'blue'

                        },
                    })}
                    positionActionsColumn="last"
                    enableRowActions
                    renderRowActions={({ row }) => (
                        <Box>
                            <IconButton onClick={() => navigate("/usercontrol/editar", { state: row.original })}>
                                <EditIcon />
                            </IconButton>
                            {row.original.role !== 'admin' ?
                                <IconButton onClick={() => dialogo(row.original)}>
                                    <LockPersonIcon sx={{ color: row.original.bloqueado === 'SI' ? 'green' : 'red' }} />
                                </IconButton>
                                : <></>}
                        </Box>
                    )}
                />
            </Container>

            <Dialog open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography align="center">
                        {"¿ Confirma que quiere " + (datos.bloqueado === "NO" ? "bloquear" : "desbloquear") + " este usuario?"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText textAlign={"center"}>
                        Se bloqueará el usuario {datos.apellido + ", " + datos.nombrepila + "-(" + datos.username + " )"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={4} direction="row">
                        <Button
                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            Cancela
                        </Button>
                        <Button
                            style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                            onClick={() => bloquearUsuario(datos.bloqueado === 'SI' ? 'NO' : 'SI')}
                            variant="contained"
                        >
                            {datos.bloqueado === 'NO' ? "Bloquear" : "Desbloquear"}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default Usuarios