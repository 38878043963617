import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MailIcon from "@mui/icons-material/Mail";
import Badge from "@mui/material/Badge";
import MenuIcon from '@mui/icons-material/Menu';
import { Toolbar, Typography, IconButton } from "@mui/material";
import Footer4 from "./Footer4";
import { cuentamensajes } from "../services/services";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";

export default function Navbar() {
  let navigate = useNavigate();
  const [cantmensajes, setCantMensajes] = useState(0)
  const [openMenu, setOpenMenu] = useState(false)

  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }



  return (

    <div>
      <AppBar
        position="fixed"
        style={{ background: "#eaeff1", color: "navy", flexGrow: 1 }}
      >
        <Toolbar variant="dense">
          <IconButton onClick={() => navigate("/menu")} title="Menu del sistema" color="primary">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Italgas
          </Typography>
          <IconButton onClick={() => navigate("/infouser")} title="Opciones de usuario">
            <AccountCircleIcon />
          </IconButton>
          <IconButton aria-label="Mensajes nuevos" onClick={() => navigate("")} title="Notificaciones del sistema">
            <Badge badgeContent={cantmensajes} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton onClick={handleLogout} color="primary" title="Salir">
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {openMenu ? <Menu /> : null}

      <Footer4 />
    </div>
  );
}
