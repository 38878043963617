import {
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Divider,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import moment from "moment";
import Navbar from "../Navbar";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { UrlApi } from "../../services/apirest";

export default function FarmaciaListado() {
  const [fechaDesde, setFechaDesde] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [fechaHasta, setFechaHasta] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [data, setData] = useState([]);

  const buscardatos = () => {
    fetch(
      UrlApi +
      "/recetas/listadofarmacia/" +
      localStorage.getItem("user") +
      "/" +
      fechaDesde +
      "/" +
      fechaHasta
    )
      .then((data) => data.json())
      .then((data) => setData(data));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "recetas_id",
        header: "NroReceta",
        maxSize: 20,
      },
      {
        accessorKey: "token", //normal accessorKey
        header: "Token",
        size: 50,
      },
      {
        accessorKey: "fventa",
        header: "FechaVenta",
        size: 20,
        //custom conditional format and styling
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString?.("es", {
            weekday: "short",
            dateStyle: "short",
            year: "numeric",
            month: "2-digit",
            day: "numeric",
          }),
      },
      {
        accessorKey: "apellido", //normal accessorKey
        header: "Paciente",
        size: 50,
      },
      {
        accessorFn: (row) => `${row.medicamento1} >***> ${row.reemplazo1}`,
        header: "Medicamento 1",
        size: 50,
        Cell: ({ cell }) => {
          return <div>{cell.getValue().substr(1, 20)}
            <p style={{ margin: '0px' }}>{cell.getValue().substr(21, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(41, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(61, 20)}</p>
          </div>;
        },
      },
      {
        accessorKey: "cant1", //normal accessorKey
        header: "Cant",
        size: 10,
      },
      {
        accessorFn: (row) => `${row.medicamento2} >***> ${row.reemplazo2}`,
        header: "Medicamento 2",
        size: 50,
        Cell: ({ cell }) => {
          return <div>{cell.getValue().substr(1, 20)}
            <p style={{ margin: '0px' }}>{cell.getValue().substr(21, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(41, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(61, 20)}</p>
          </div>;
        },
      },
      {
        accessorKey: "cant2", //normal accessorKey
        header: "Cant",
        size: 10,
      },
      {
        accessorFn: (row) => `${row.medicamento3} >***> ${row.reemplazo3}`,
        header: "Medicamento 3",
        size: 50,
        Cell: ({ cell }) => {
          return <div>{cell.getValue().substr(1, 20)}
            <p style={{ margin: '0px' }}>{cell.getValue().substr(21, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(41, 20)}</p>
            <p style={{ margin: '0px' }}>{cell.getValue().substr(61, 20)}</p>
          </div>;
        },
      },
      {
        accessorKey: "cant3", //normal accessorKey
        header: "Cant",
        size: 10,
      },
    ],
    []
  );

  return (
    <div>
      <Navbar />
      <Container
        sx={{ marginLeft: "5px", marginRight: "0px", marginTop: "65px" }}
      >
        <Typography variant="h5">
          Listado de control ventas confirmadas entre fechas
        </Typography>
        <Typography variant="h5">
          {localStorage.getItem("apellidonombre")}-
          {localStorage.getItem("user")}
        </Typography>
        <Divider sx={{ borderBottomWidth: "3px", borderColor: "red" }} />
        <Grid container spacing={2} alignItems="center" marginTop={"5px"}>
          <Grid item xs={2}>
            <TextField
              name="desde"
              required
              label="Fecha desde"
              value={fechaDesde}
              onChange={(e) =>
                setFechaDesde(moment(e.target.value).format("YYYY-MM-DD"))
              }
              type="date"
              size="small"
              fullWidth
              inputProps={{
                max: moment(new Date()).format("YYYY-MM-DD"),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="hasta"
              required
              label="Fecha hasta"
              value={fechaHasta}
              onChange={(e) =>
                setFechaHasta(moment(e.target.value).format("YYYY-MM-DD"))
              }
              type="date"
              size="small"
              fullWidth
              inputProps={{
                min: fechaDesde,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" color="success" onClick={buscardatos}>
              Buscar datos
            </Button>
          </Grid>
        </Grid>
        <MaterialReactTable
          columns={columns}
          data={data}
          localization={MRT_Localization_ES}
          enableDensityToggle={false}
          enableColumnFilters={false}
          enableTableFooter={false}
          initialState={{
            density: "compact",
            expanded: false,
          }}
          enableHiding={false}
          enableColumnActions={false}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
          enablePagination={false}
        />
      </Container>
    </div>
  );
}
