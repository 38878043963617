import { Avatar, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleIcon from '@mui/icons-material/People';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import { UrlApi, AuthApi } from '../../services/apirest'
import { v4 as uuidv4 } from 'uuid'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ConfirmDialog2 from '../../services/ConfirmDialog2'
import { AttachEmail } from '@mui/icons-material';
import { enviarmanual } from '../../services/services';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function UserControl() {
    let { accion } = useParams()
    let navigate = useNavigate()
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const [openDialog, setOpendialog] = useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [mensaje, setMensaje] = useState("oops")
    const [sucursales, setSucursales] = useState()
    const [sucursalSelected, setSucursalSelected] = useState(accion === 'editar' ? location.state.sucursal : "")

    const [banco, setBanco] = useState()
    const [bancoSelected, setBancoSelected] = useState(accion === 'editar' ? location.state.banco : "")

    const [obrasocial, setObrasocial] = ('')

    const [legajoUuid, setLegajoUuid] = useState(uuidv4())    //// generamos un uuid para cuando sea nuevo lo usemos

    const [familiarborrar, setFamiliarBorrar] = useState([])

    //const [role, setRole] = useState("admin")


    useEffect(() => {
        obtenerSucursales()
        obtenerBancos()
        accion === "editar" && buscarFamiliares()
    }, [])


    ///Traer sucursales para los selects
    const obtenerSucursales = async () => {
        const data = await fetch(UrlApi + "/sucursales")
        const sucursalesData = await data.json()
        setSucursales(sucursalesData)
    }

    const resetPass = async () => {
        const data = await fetch(UrlApi + "/userpass/" + location.state.id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                password: "123456",
            }),
        })
            .then((res) => (res.ok ? Snackp() : Snackx()))
            .catch((err) => {
                console.log(err.message);
            });
    };

    // abre el snackbar
    const Snackp = () => {
        setMensaje("Se Reseteo la contraseña a 123456");
        setOpen(true);
        //setColorMensaje("green");
    };

    const Snackx = () => {
        setMensaje("Error , no se pudo resetar la contraseña !!");
        setOpen(true);
        //setColorMensaje("red");
    };
    //////---------------------------------

    const obtenerBancos = async () => {
        const data = await fetch(UrlApi + "/bancos")
        const bancosData = await data.json()
        setBanco(bancosData)
    }

    const [datosLegajos, setDatosLegajos] = useState(accion === "nuevo" ?
        {
            nombre: "",
            nombrepila: "",
            apellido: "",
            username: "",
            id_legajo: legajoUuid,
            email: "",
            legajo: "",
            telefonomovil: "+549",
            password: "123456", ///ponemos esto de passsword incial
            password_repeat: "123456", ///ponemos esto de passsword incial
            banco: "",
            role: "usuario",
            obrasocial: ""
        } :
        {
            nombre: location.state.nombrepila,
            apellido: location.state.apellido,
            sucursal: location.state.sucursal,
            legajo: location.state.legajo,
            email: location.state.email,
            telefonomovil: location.state.telefonomovil,
            banco: location.state.banco,
            cbu: location.state.cbu,
            cuil: location.state.cuil,
            username: location.state.username,
            password: "123456", ///ponemos esto de passsword incial
            password_repeat: "123456", ///ponemos esto de passsword incial
            role: location.state.role,
            obrasocial: location.state.obrasocial
        }
    )

    const [datosFamiliar, setDatosFamiliar] = useState([])

    const handleCloseDialog = () => {
        setOpendialog(!openDialog)
    }

    const dialogo = (fila) => {
        setOpendialog(true)
        setFamiliarBorrar(fila)
        familiarborrar && console.log("borra", familiarborrar)
    }

    const handleChanges = (e) => {
        setDatosLegajos({
            ...datosLegajos,
            [e.target.name]: e.target.value
        })
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        navigate("/users")
    };


    ////para los familiares
    const buscarFamiliares = async () => {
        const data = await fetch(UrlApi + "/pacientes/familiar/" + location.state.id_legajos)
        const familiaresData = await data.json()
        setDatosFamiliar(familiaresData)
    }

    const borrarFamiliar = async () => {
        await fetch(UrlApi + "/pacientes/familiar/" + familiarborrar.id_familiar, {
            method: 'DELETE', headers: { 'Content-Type': 'application/json' }
        })
            .then(buscarFamiliares, setOpendialog(false))
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const handleAddFamiliar = () => {
        const values = [...datosFamiliar];
        values.push({
            id_familiar: null,
            id_legajos: accion === 'nuevo' ? legajoUuid : location.state.id_legajos,
            nombre: "",
            apellido: "",
            dni: "",
            parentesco: ""
        });
        setDatosFamiliar(values);
    };

    const handleRemoveFamiliar = (index) => {
        const values = [...datosFamiliar];
        values.splice(index, 1);
        setDatosFamiliar(values);
    };

    const handleFamiliarChange = (index, event) => {
        const values = [...datosFamiliar];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setDatosFamiliar(values);
    };

    const handleGrabarFamilares = async () => {
        await fetch(UrlApi + "/pacientes", {
            method: 'POST', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datosFamiliar.filter((res) => res.nombre.length > 0 && res.apellido.length > 0 && res.parentesco.length > 0))
        })
    }


    const handleModificaFamiliar = async () => {
        await fetch(UrlApi + "/pacientes/familiar", {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(datosFamiliar.filter((res) => res.nombre.length > 0 && res.apellido.length > 0 && res.parentesco.length > 0))
        })
    }
    ////---- fin familiares


    const handleAction = async (e) => {
        e.preventDefault();
        /// si la accion es editar paso los props recibidos a travez de location.state
        accion === "editar" ?
            await fetch(UrlApi + '/userdata/legajos/' + location.state.id_legajo, {
                method: "PUT", headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id_legajos: datosLegajos.id_legajos,
                    legajo: datosLegajos.legajo,
                    sucursal: sucursalSelected,
                    nombre: datosLegajos.nombre,
                    apellido: datosLegajos.apellido,
                    email: datosLegajos.email,
                    telefonomovil: datosLegajos.telefonomovil,
                    cuil: datosLegajos.cuil,
                    cbu: datosLegajos.cbu,
                    banco: bancoSelected,
                    obrasocial: datosLegajos.obrasocial
                })
            })
                .then(res => res.status === 200 ?
                    [setOpen(true), setMensaje("Usuario Actualizado")]
                    : [setOpen(true), setMensaje(res.msg)])
                .catch(err => console.log(err))
                .then(await fetch(UrlApi + '/userdata/users/' + location.state.id_legajo, {
                    method: "PUT", headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id_legajo: datosLegajos.id_legajos,
                        sucursal: sucursalSelected,
                        nombrepila: datosLegajos.nombre,
                        apellido: datosLegajos.apellido,
                        email: datosLegajos.email,
                        celular: datosLegajos.telefonomovil,
                        role: datosLegajos.role,
                    })
                })
                    .then(res => res.status === 200 ?
                        [setOpen(true), setMensaje("Usuario Actualizado")]
                        : [setOpen(true), setMensaje(res.msg)])
                    .catch(err => console.log(err)))
            :
            await fetch(AuthApi + "sign-up", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: datosLegajos.username,
                    nombrepila: datosLegajos.nombre,
                    apellido: datosLegajos.apellido,
                    email: datosLegajos.email,
                    celular: datosLegajos.telefonomovil,
                    sucursal: sucursalSelected,
                    id_legajo: legajoUuid,
                    password: "123456", ///ponemos esto de passsword incial
                    password_repeat: "123456", ///ponemos esto de passsword incial
                    role: datosLegajos.role,
                }
                )
            }).then(res => res.status === 201 ?
                [setOpen(true), setMensaje("Usuario registrado")]
                : [setOpen(true), setMensaje("Error usuario existente")])
                .catch(err => console.log(err))
                .then(await fetch(UrlApi + "/userdata", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id_legajos: legajoUuid,
                        legajo: datosLegajos.legajo,
                        sucursal: sucursalSelected,
                        nombre: datosLegajos.nombre,
                        apellido: datosLegajos.apellido,
                        email: datosLegajos.email,
                        telefonomovil: datosLegajos.telefonomovil,
                        cuil: datosLegajos.cuil,
                        cbu: datosLegajos.cbu,
                        banco: bancoSelected,
                        obrasocial: datosLegajos.obrasocial
                    })
                })
                    .then(res => res.status === 200 ?
                        [setOpen(true), setMensaje("Usuario Actualizado")]
                        : [setOpen(true), setMensaje(res.msg)])
                    .catch(err => console.log(err))
                )

        datosFamiliar.length > 0 ? handleModificaFamiliar() : console.log("no")

        //enviarmails(mail[0].email, "Aviso de requerimiento de materiales para aprobar", `<p>Tiene un requerimiento para aprobar de ${datosFormulario.usuario_registra}</p>`)
    };

    const enviarmanualalusuario = () => {
        enviarmanual(location.state.email, "Manual del usuario del sistema de autorizaciones de medicamentos",
            `<p>Estimada/o <b>${location.state.nombre}</b></p>
         <p> Adjunto encontrara el manual del usuario del sistema.</p><br>Saludos`
        )
    }

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: '60px' }} >
                {datosLegajos ?
                    <form onSubmit={handleAction} autoComplete="off">
                        <Typography variant='h5' sx={{ marginBottom: '15px' }}>Gestion de usuarios del sistema</Typography>
                        <Typography variant='h5' sx={{ border: '1px solid teal', padding: '1px', width: '80%', borderRadius: '5px', marginBottom: '15px' }}>
                            <PeopleIcon color='primary' /> Datos del usuario
                        </Typography>
                        <Grid container sx={{ width: '100%', marginBottom: '10px' }} spacing={1}>
                            <Grid item xs={2}>
                                <InputLabel id="demo-multiple-checkbox-label">Perfil principal</InputLabel>
                                <Select
                                    input={<OutlinedInput label="Perfil" />}
                                    value={datosLegajos.role}
                                    onChange={handleChanges}
                                    size='small'
                                    name='role'
                                    fullWidth
                                >
                                    <MenuItem value={"usuario"}>Usuario</MenuItem>
                                    <MenuItem value={"admin"}>Administrador</MenuItem>
                                    <MenuItem value={"medicina"}>Medicina Laboral</MenuItem>
                                    <MenuItem value={"farmacia"}>Farmacia</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel id="demo-multiple-checkbox-label">Obra Social</InputLabel>
                                <Select
                                    input={<OutlinedInput label="Obra Social" />}
                                    value={datosLegajos.obrasocial}
                                    onChange={handleChanges}
                                    size='small'
                                    name='obrasocial'
                                    fullWidth
                                >
                                    <MenuItem value={"ospegap"}>OSPEGAP</MenuItem>
                                    <MenuItem value={"otro"}>Otro</MenuItem>

                                </Select>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ width: '100%', marginBottom: '10px' }} spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="Usuario"
                                    placeholder='Numero de CUIL'
                                    name='username'
                                    size='small'
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={datosLegajos.username}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 11 }}
                                    disabled={accion === 'editar' ? true : false}
                                    title='Colocar el nro de cuil como usuario' />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="Nombre"
                                    name='nombre'
                                    size='small'
                                    fullWidth
                                    value={datosLegajos.nombre}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 100 }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="Apellido"
                                    name='apellido'
                                    size='small'
                                    fullWidth
                                    value={datosLegajos.apellido}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 100 }} />
                            </Grid>
                        </Grid>

                        <Grid container sx={{ width: '100%', marginBottom: '10px' }} spacing={1}>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="E-Mail"
                                    name='email'
                                    size='small'
                                    fullWidth
                                    value={datosLegajos.email}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 100 }} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="Celular"
                                    name='telefonomovil'
                                    size='small'
                                    fullWidth
                                    value={datosLegajos.telefonomovil}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 100 }}
                                    title='Ejemplo +5493411234567  +549 codigo de area y numero' />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="labelsucursal">Sucursal</InputLabel>
                                    <Select
                                        required
                                        fullWidth={true}
                                        margin="dense"
                                        value={sucursalSelected}
                                        variant="outlined"
                                        size="small"
                                        labelId="labelsucursal"
                                        label='Sucursal'
                                        defaultValue=""
                                        onChange={(e) => { setSucursalSelected(e.target.value); }}
                                    >
                                        {sucursales ? sucursales.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: '100%', marginBottom: '10px' }} spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="Legajo"
                                    name='legajo'
                                    size='small'
                                    fullWidth
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={datosLegajos.legajo}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="CUIL"
                                    name='cuil'
                                    size='small'
                                    fullWidth
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={datosLegajos.cuil}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 11 }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="labeldelbanco">Banco</InputLabel>
                                    <Select
                                        required
                                        fullWidth={true}
                                        margin="dense"
                                        value={bancoSelected}
                                        variant="outlined"
                                        size="small"
                                        labelId='labeldelbanco'
                                        label='Banco'
                                        defaultValue=""
                                        onChange={(e) => { setBancoSelected(e.target.value); }}
                                    >
                                        {banco ? banco.map(elemento => (
                                            <MenuItem key={elemento.id} value={elemento.id}>
                                                {elemento.nombre}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    variant='outlined'
                                    label="CBU"
                                    name='cbu'
                                    size='small'
                                    fullWidth
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={datosLegajos.cbu}
                                    onChange={handleChanges}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Grid>

                        </Grid>

                        <Typography variant='h5' sx={{ border: '1px solid teal', padding: '1px', width: '80%', borderRadius: '5px', marginBottom: '15px' }}>
                            <FamilyRestroomIcon color='primary' /> Familiares
                            <IconButton sx={{ float: "right", padding: '5px' }} title='Agregar familiar' onClick={handleAddFamiliar} >
                                <GroupAddIcon color='secondary' />
                            </IconButton>
                        </Typography>
                        {datosFamiliar ? datosFamiliar.map((field, index) => (
                            <Grid container sx={{ width: '100%', marginBottom: '10px' }} spacing={1} key={index}>
                                <IconButton
                                    variant="outlined"
                                    color='error'
                                    onClick={() => accion !== 'editar' ? handleRemoveFamiliar(index) : dialogo(field)}
                                    sx={{ padding: '0px' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        variant='outlined'
                                        label="Nombre"
                                        name='nombre'
                                        size='small'
                                        fullWidth
                                        value={field.nombre}
                                        onChange={(e) => handleFamiliarChange(index, e)}
                                        inputProps={{ maxLength: 100 }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        variant='outlined'
                                        label="Apellido"
                                        name='apellido'
                                        size='small'
                                        fullWidth
                                        value={field.apellido}
                                        onChange={(e) => handleFamiliarChange(index, e)}
                                        inputProps={{ maxLength: 100 }} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        required
                                        variant='outlined'
                                        label="DNI"
                                        name='dni'
                                        size='small'
                                        fullWidth
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        value={field.dni}
                                        onChange={(e) => handleFamiliarChange(index, e)}
                                        inputProps={{ maxLength: 11 }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl>
                                        <InputLabel>Parentesco</InputLabel>
                                        <Select
                                            size="small"
                                            style={{ width: 150 }}
                                            value={field.parentesco}
                                            label="Parentesco"
                                            name="parentesco"
                                            defaultValue=""
                                            onChange={(e) => handleFamiliarChange(index, e)}
                                        >
                                            <MenuItem value={'Conyuge'}>Conyuge</MenuItem>
                                            <MenuItem value={'Hijo'}>Hijo</MenuItem>
                                            <MenuItem value={'Otro'}>Otro</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        )) : <></>}

                        <Divider sx={{ marginBottom: '10px', width: '80%', borderWidth: 2 }} />
                        <Grid container sx={{
                            width: '80%', marginBottom: '10px', display: "flex",
                            justifyContent: "right", paddingRight: '30px', paddingBottom: '10px', marginLeft: '5px',
                        }} spacing={1}>
                            <Grid item xs={2}>
                                <Button size='small' variant='outlined' color='error' onClick={() => navigate("/users")}>Cancelar</Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button type="submit" size='small' variant='outlined' color='success' >Guardar</Button>
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '80%', borderWidth: 2 }} />
                    </form>
                    : <></>}
                {accion === 'editar' ?
                    <>
                        <Card sx={{ width: '80%', }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: "orange", cursor: 'pointer' }} variant="square" onClick={() => setConfirmOpen(true)}>
                                        <VpnKeyIcon />
                                    </Avatar>
                                }
                                title="Resetear contraseña"
                            />
                        </Card>
                        <Card sx={{ width: '80%', }}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: "navy", cursor: 'pointer' }} variant="square" onClick={() => enviarmanualalusuario()}>
                                        <AttachEmail />
                                    </Avatar>
                                }
                                title="Enviar Manual Usuario"
                            />
                        </Card>
                    </>
                    : <></>}

            </Container >

            {/* dialogo Borrar familiar */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {familiarborrar && <>
                    <DialogTitle>
                        <Typography align="center">
                            {"¿ Confirma que quiere borrar esta Familiar ?"}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText textAlign={"center"}>
                            Se eliminará el familiar {familiarborrar.nombre}, {familiarborrar.apellido}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Stack spacing={4} direction="row">
                            <Button
                                style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                                variant="contained"
                                onClick={handleCloseDialog}
                            >
                                Cancela
                            </Button>
                            <Button
                                style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                                onClick={() => borrarFamiliar()}
                                variant="contained"
                            >
                                Confirma
                            </Button>
                        </Stack>
                    </DialogActions></>
                }
            </Dialog>

            <ConfirmDialog2
                title="Resetea contraseña?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={resetPass}
            >
                Estas seguro de resetear la contraseña de este usuario?
            </ConfirmDialog2>


            <Snackbar
                open={open}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
                message={mensaje}
            />
        </div >
    )
}
