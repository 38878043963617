import React, { useState, useMemo, useEffect } from 'react'
import Navbar from './Navbar'
import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { UrlApi } from '../services/apirest';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DoneAll, DonutLarge, DownloadForOffline, IntegrationInstructions, MonetizationOn } from '@mui/icons-material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import moment from 'moment'
import ConfirmDialog from './ConfirmDialog';
import { enviarmails2, enviarsms } from '../services/services';

export default function Apagar() {

    const [opensnack, setOpenSnack] = useState(false)
    const [mensaje, setMensaje] = useState("oops")
    const [data, setData] = useState()
    const [openDialog, setOpendialog] = useState(false)
    const [rowSelection, setRowSelection] = useState({});
    const [totalPago, setTotalPago] = useState(0);
    const [datosSeleccionados, setDatosSeleccionados] = useState([]);
    const [lotepago, setLotepago] = useState(0)
    const [lotepagoNoPagado, setLotepagoNoPagado] = useState(0)
    const [lotepagoPagado, setLotepagoPagado] = useState(0)

    const [banco, setBanco] = useState()
    const [bancoSelected, setBancoSelected] = useState(null)
    const [fechapago, setFechaPago] = useState(moment(new Date()).format("YYYY-MM-DD"))

    const [jsonData, setJsonData] = useState();
    const [jsonData2, setJsonData2] = useState();
    const [archivo, setArchivo] = useState("")
    const [opsisven, setOpSisven] = useState("")

    const [confirmOpen, setConfirmOpen] = useState(false)

    const [reintegroofarmacia, setReintegroOFarmacia] = useState(true)

    const [mails, setMails] = useState([])

    const [showMailAlert, setShowMailAlert] = useState(false)

    useEffect(() => {
        traerDatos()
        traerUltimoLotePago()
        traerLotePagoNoPagados()
        obtenerBancos()
        setRowSelection({})
    }, [reintegroofarmacia])

    useEffect(() => {
        downloadTextFile()
    }, [jsonData, jsonData2])



    /*    useEffect(() => {
           mails && mails.forEach((item) => {
               //enviarEmail(item.email, item.recetas_id, item.nombre, item.apellido);
               enviarmails2(
                   item.email,
                   "Aviso de Transferencia - Medicamentos",
                   `<p>Estimada/o <b>${item.nombre + " " + item.apellido}</b></p>
                   <p>En los próximos días recibirá una transferencia por un valor reintegro de medicamentos 
                   de la receta <b>${item.recetas_id}</b></p>
                   <p>Cualquier inconveniente comunicarse con RRHH</p>`
               )
               enviarsms(
                   item.telefonomovil,
                   "Hola " +
                   item.nombre +
                   ", Recibiras una transferencia por un valor reintegro de medicamentos en los proximos dias por la receta " +
                   item.recetas_id +
                   " .Saludos "
               )
           })
       }, [mails]) */



    const traerDatos = () => {
        reintegroofarmacia ?
            fetch(UrlApi + '/recetas/todas/pagar')
                .then(data => data.json())
                .then(data => setData(data))
            :
            fetch(UrlApi + '/recetas/todas/farmacia')
                .then(data => data.json())
                .then(data => setData(data))
    }

    const enviarEmail = (mail, receta, nombre, apellido) => {
        console.log("enviandomail", mail, receta, nombre, apellido)
    }

    const enviarmailsatodos = async (lotep) => {
        try {
            const response = await fetch(UrlApi + '/recetas/enviomailsatodos/' + lotep);
            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }

            const data = await response.json();
            setMails(data); // Actualiza el estado de 'mails' con los datos

            // Espera a que todas las solicitudes de envío de correos se completen antes de continuar
            const emailPromises = data.map(async (item) => {
                //await enviarEmail(el.email, el.recetas_id, el.nombre, el.apellido);

                await enviarmails2(
                    item.email,
                    "Aviso de Transferencia - Medicamentos",
                    `<p>Estimada/o <b>${item.nombre + " " + item.apellido}</b></p>
                    <p>En los próximos días recibirá una transferencia por un valor reintegro de medicamentos 
                    de la receta <b>${item.recetas_id}</b></p>
                    <p>Cualquier inconveniente comunicarse con RRHH</p>`
                )
                await enviarsms(
                    item.telefonomovil,
                    "Hola " +
                    item.nombre +
                    ", Recibiras una transferencia por un valor reintegro de medicamentos en los proximos dias por la receta " +
                    item.recetas_id +
                    " .Saludos "
                )

            });
            await Promise.all(emailPromises);
            // Muestra la alerta después de que el proceso de envío de correos electrónicos haya finalizado con éxito
            setShowMailAlert(true);
        } catch (error) {
            // Maneja cualquier error que pueda ocurrir durante la solicitud o el procesamiento de datos
            console.error("Error:", error);
        }
    }


    const traerUltimoLotePago = () => {
        fetch(UrlApi + '/recetas/ultimolotepago/1')
            .then(data => data.json())
            .then(data => setLotepago(data[0].lote + 1))
    }

    const traerLotePagoNoPagados = () => {
        fetch(UrlApi + '/recetas/lotepago/sinpagar/1')
            .then(data => data.json())
            .then(data => setLotepagoNoPagado(data))
    }

    const traerInterbanking = async (lote) => {
        setArchivo("Inter" + lote + ".txt")
        await fetch(UrlApi + '/recetas/lotepago/' + lote)
            .then(data => data.json())
            .then(data => setJsonData(data))
    }

    const armarOrdenDePago = async (lote) => {
        setArchivo("OrdenDePago" + lote + ".txt")
        await fetch(UrlApi + '/recetas/ordenpago/' + lote)
            .then(data => data.json())
            .then(data => setJsonData2(data))
    }

    /*    const traerOPSisven = async (lote) => {
           setArchivo2("OP-Sisven" + lote + ".txt")
           await fetch(UrlApi + '/recetas/ordenpagosisven/' + lote)
               .then(data => data.json())
               .then(data => setJsonDataSisvenOP(data))
       } */

    const obtenerBancos = async () => {
        const data = await fetch(UrlApi + "/bancos")
        const bancosData = await data.json()
        setBanco(bancosData)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
        setRowSelection({})
        traerDatos()
    };

    const handleCloseDialog = () => {
        setOpendialog(!openDialog)
    }


    useEffect(() => {

        const { datosSeleccionados, totalPago } = Object.keys(rowSelection).reduce((result, key) => {
            if (rowSelection[key]) {
                result.datosSeleccionados.push({ recetas_id: data[key].recetas_id, lotepago: lotepago, fechapago: fechapago, bancodebito: bancoSelected })
                result.totalPago += data[key].abonar;
            }
            return result;
        }, { datosSeleccionados: [], totalPago: 0 });

        setDatosSeleccionados(datosSeleccionados);
        setTotalPago(totalPago);
    }, [rowSelection, data, lotepago, fechapago, bancoSelected]);

    const actualizarLotePago = async () => {
        try {
            const response = await fetch(UrlApi + "/recetas/lotepago/1", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ datosSeleccionados })
            });

            const resultado = await response.text();
            setMensaje(resultado);
            setOpendialog(false)
            setOpenSnack(true)
            traerUltimoLotePago()
            traerLotePagoNoPagados()
        } catch (error) {
            console.error(error.message);
        }
    };

    const handlePagado = (lote) => {
        setOpSisven("")
        setConfirmOpen(true)
        setLotepagoPagado(lote)
    }

    const pagado = async () => {
        await fetch(UrlApi + "/recetas/pagado/" + lotepagoPagado, {
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                pagado: 1,
                op_sisven: opsisven
            })
        }).then(res => res.ok ? [console.log("ok"),
        traerDatos(),
        traerUltimoLotePago(),
        traerLotePagoNoPagados()] : console.log(res))
            .catch(err => console.log(err))
    }

    function downloadTextFile() {
        if (jsonData) {
            const dataString = jsonData.map(obj => Object.values(obj).join(',')).join('\r\n');
            const blob = new Blob([dataString], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = archivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        /*        if (jsonData2) {
                   console.log(jsonData2)
                   const dataString = jsonData2.map(obj => Object.values(obj).join(',')).join('\r\n');
                   const blob = new Blob([dataString], { type: 'text/plain' });
                   const url = URL.createObjectURL(blob);
                   const link = document.createElement('a');
                   link.href = url;
                   link.download = archivo;
                   document.body.appendChild(link);
                   link.click();
                   document.body.removeChild(link);
               } */

        if (jsonData2) {
            console.log(jsonData2);
            const dataString = jsonData2.map(obj => Object.values(obj).join(',') + '\r\n').join('');
            const blob = new Blob([dataString], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = archivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'recetas_id', //access nested data with dot notation
                header: 'NroReceta',
                size: 10,
            },
            {
                accessorKey: 'fecharegistro',
                header: 'Solicitado',
                size: 20,
                //custom conditional format and styling
                Cell: ({ cell }) => (
                    cell.getValue()?.toLocaleString?.('es', {
                        weekday: "short",
                        dateStyle: "short",
                        year: "numeric",
                        month: "2-digit",
                        day: "numeric"
                    })
                ),
            },
            {
                accessorKey: 'solicitante', //normal accessorKey
                header: 'Solicito',
                size: 50
            },
            {
                accessorKey: 'empresa',
                header: 'Empresa',
                size: 30
            },
            {
                accessorKey: 'farmanombre',
                header: 'Farmacia',
                size: 30
            },
            {
                accessorKey: 'abonar',
                header: 'A Pagar',
                size: 40
            },
            {
                accessorKey: 'lotepago',
                header: 'LtePgo',
                size: 30
            },
        ],
        [],
    )




    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: "60px", marginLeft: '10px' }}>
                <Grid container direction="rows">
                    <Grid item xs={9}>
                        <Typography variant='h6' sx={{ backgroundColor: 'teal', color: 'white', paddingLeft: '10px' }}> Preparar pagos
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='h6' sx={{ backgroundColor: 'orange', color: 'white', paddingLeft: '10px' }}>Lotes de Pago
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        {data ?
                            <MaterialReactTable
                                columns={columns}
                                data={data}
                                localization={MRT_Localization_ES}
                                enableDensityToggle={false}
                                enableColumnFilters={false}
                                enableTableFooter={false}
                                initialState={{
                                    density: 'compact',
                                    expanded: false,
                                }}
                                enableHiding={false}
                                enableColumnActions={false}
                                enableStickyHeader
                                muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
                                enablePagination={false}
                                enableRowSelection={(row) => row.original.lotepago === 0} //enable row selection conditionalmente a los que no tienen lotepago o sea lotepago=0
                                onRowSelectionChange={setRowSelection}
                                state={{ rowSelection }}
                                positionToolbarAlertBanner='none'

                                muiTableHeadCellProps={{
                                    //simple styling with the `sx` prop, works just like a style prop in this example
                                    sx: {
                                        fontWeight: 'normal',
                                        fontSize: '14px',
                                        border: '1px solid black',
                                        color: 'white',
                                        backgroundColor: 'teal'
                                    },
                                }}

                                muiTableBodyCellProps={({ row }) => ({
                                    sx: {
                                        background:
                                            row.original.lotepago > 0 ? "lightgreen" : "none",
                                        borderColor: ' red',
                                        fontSize: 12

                                    },
                                })}

                                renderTopToolbarCustomActions={({ table }) => (
                                    <Stack spacing={10} direction="row">
                                        <Button
                                            disabled={table.getRowModel().rows.length === 0 || JSON.stringify(rowSelection) === '{}'}
                                            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                            onClick={() => setOpendialog(true)}
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                            size='small'
                                        >
                                            Preparar Pago
                                        </Button>
                                        <Button
                                            onClick={() => setReintegroOFarmacia(!reintegroofarmacia)}
                                            startIcon={<IntegrationInstructions />}
                                            variant="contained"
                                            size='small'
                                        >
                                            Reintegro o Farmacia
                                        </Button>

                                        <Typography variant='h5' >
                                            Seleccionar recetas para pago {reintegroofarmacia ? 'por Reintegro' : 'a Farmacia'}
                                        </Typography>
                                    </Stack>
                                )}

                                renderBottomToolbarCustomActions={({ table }) => (
                                    <Typography sx={{ color: 'grey', fontSize: '18px' }} >
                                        Total a pagar $ {totalPago.toFixed(2)}
                                    </Typography>
                                )}
                            />
                            : <></>}
                    </Grid>

                    <Grid item xs={3} sx={{ height: '400px', overflowY: 'auto', marginTop: '10px', border: '1px solid blue' }}>

                        {lotepagoNoPagado ? lotepagoNoPagado.map((lote, index) =>
                            <Card sx={{ border: '1px solid black', boxShadow: 10, margin: '10px' }} key={index}>
                                {console.log(lote.farma)}
                                {lote.farma > 0 ?
                                    <Typography variant='h6' sx={{ color: 'white', backgroundColor: 'blue' }}> Farmacia</Typography>
                                    : <Typography variant='h6' sx={{ color: 'white', backgroundColor: 'teal' }}> Reintegro</Typography>}
                                <CardHeader sx={{ padding: '10px' }}
                                    action={
                                        <Stack spacing={0}>
                                            {/*   <IconButton onClick={() => traerInterbanking(lote.lotepago)} disabled={true} >
                                                <DownloadForOffline color='info' />
                                            </IconButton> */}
                                            {!lote.farma > 0 ?
                                                <>
                                                    <IconButton onClick={() => enviarmailsatodos(lote.lotepago)} title='Enviar mails' >
                                                        <ForwardToInboxIcon color='info' />
                                                    </IconButton>
                                                    <IconButton onClick={() => armarOrdenDePago(lote.lotepago)} title='Generar archivo para Sisven'>
                                                        <MonetizationOn color='success' />
                                                    </IconButton>
                                                </>
                                                : <></>}


                                            <IconButton onClick={() => handlePagado(lote.lotepago)} title='Marca lote como pagado'>
                                                <DoneAll color='error' />
                                            </IconButton>
                                        </Stack>
                                    }
                                    title={lote.lotepago + " - " + moment(lote.fechapago).format("DD/MM/YYYY")}
                                    subheader={"Banco debito :" + lote.banconombre + " -- $ " + lote.apagar}
                                    titleTypographyProps={{ variant: 'body1' }}
                                    subheaderTypographyProps={{ variant: 'caption' }}
                                />
                            </Card>)
                            : <></>}
                    </Grid>
                </Grid>

            </Container>

            <Snackbar
                open={opensnack}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
                message={mensaje}
                ContentProps={{ sx: { background: "teal", color: 'white' } }}
            />

            <Snackbar
                open={showMailAlert}
                autoHideDuration={2000} // Controla cuánto tiempo se mostrará la alerta (en milisegundos)
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setShowMailAlert(false)} // Cierra la alerta cuando el usuario hace clic en ella
                message="¡El proceso de envío de correos electrónicos ha finalizado!"
                ContentProps={{ sx: { background: "green", color: 'white' } }} // Estilo de la alerta
            />


            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography align="center">
                        Crear lote de pago
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText textAlign={"center"}>
                        Se creara un lote de pago con las recetas seleccionadas
                    </DialogContentText>
                    <Grid container sx={{ marginTop: '15px' }}>
                        <Grid item xs={4}>
                            <TextField
                                autoFocus
                                fullWidth
                                size='small'
                                variant="outlined"
                                label='Ingrese nro de lote de pago'
                                value={lotepago}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(e) => setLotepago(e.target.value)}>
                                Lote de pago
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="labeldelbanco">Banco</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    margin="dense"
                                    value={bancoSelected}
                                    variant="outlined"
                                    size="small"
                                    labelId='labeldelbanco'
                                    label='Banco'
                                    defaultValue=""
                                    onChange={(e) => { setBancoSelected(e.target.value); }}
                                >
                                    {banco ? banco.map(elemento => (
                                        <MenuItem key={elemento.id} value={elemento.id}>
                                            {elemento.nombre}
                                        </MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required
                                fullWidth
                                label="Fecha de Pago"
                                value={fechapago}
                                onChange={(e) => setFechaPago(e.target.value)}
                                type="date"
                                size='small'
                                inputProps={{
                                    min: moment(new Date()).format("YYYY-MM-DD")
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={4} direction="row">
                        <Button
                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            Cancela
                        </Button>
                        <Button
                            disabled={bancoSelected ? false : true}
                            style={{ backgroundColor: "green", color: "#ffffff" }}
                            onClick={() => actualizarLotePago()}
                            variant="contained"
                        >
                            Confirma
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>


            <ConfirmDialog
                title="Confirmacion de Pago"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => pagado()}
            >
                <Grid sx={{ marginTop: '5px', marginBottom: '5px' }}>
                    <Grid item xs={4}>
                        <TextField
                            required
                            fullWidth
                            label="Orden Pago Sisven"
                            value={opsisven}
                            onChange={(e) => setOpSisven(e.target.value)}
                            size='small'
                        />
                    </Grid>
                </Grid>
                Confirma el pago del lote {lotepagoPagado} ?
            </ConfirmDialog>

        </div>
    )
}
