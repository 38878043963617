import React, { useEffect, useMemo, useState } from 'react'
import { UrlApi } from '../services/apirest'
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import MaterialReactTable from 'material-react-table';
import Navbar from './Navbar';
import { Lock, LockOpen } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

export default function Catalogo() {
    const [catalogo, setCatalogo] = useState()
    const [openDialog, setOpendialog] = useState(false)
    const [datos, setDatos] = useState([])

    useEffect(() => {
        leerCatalogo()
    }, [])

    const leerCatalogo = () => {
        try {
            fetch(UrlApi + '/catalogo')
                .then(data => data.json())
                .then(data => setCatalogo(data))
        } catch (error) {
            console.log(error)
        }

    }

    const dialogo = (fila) => {
        setOpendialog(true)
    }

    const handleCloseDialog = () => {
        setOpendialog(!openDialog)
        setRowSelection({})
    }

    const observarMedicamento = async (codigo, observ) => {
        console.log("paseporaca", observ + "-" + codigo)
        ///sino es el parametro recibo 1 o 0 segun este el campo "observado" en "1" o "0" espectivamente
        await fetch(UrlApi + "/catalogo/" + codigo, {     ///datos.codigobarras del medicamento a modificar
            method: 'PUT', headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ observado: observ === "0" ? 1 : 0 })
        })
            .then(console.log("listo"))
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'nombre', //access nested data with dot notation
                header: 'Nombre',
                maxSize: 20,
            },
            {
                accessorKey: 'presentacion',
                header: 'Presentacion',
                size: 50
            },
            {
                accessorKey: 'laboratorio', //normal accessorKey
                header: 'Laboratorio',
                size: 50
            },
            {
                accessorKey: 'codigobarras', //normal accessorKey
                header: 'CodBarra',
                size: 20
            },
            {
                accessorKey: 'precio', //normal accessorKey
                header: 'Precio $',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                accessorKey: 'fechavigencia',
                header: 'Vigencia',
                size: 20,
            },
            {
                accessorKey: 'origen',
                header: 'Origen',
                size: 50,
            },
            {
                accessorKey: 'TipoVta',
                header: 'TipoVta',
                size: 50,
            },
            {
                accessorKey: 'observado',
                header: 'Observado',
                size: 10,
            },

        ],
        [],
    );

    ///exportar a csv
    const csvOptions = {
        filename: 'farmaCatalogo',
        fieldSeparator: ';',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };


    //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {
        //do something when the row selection changes...
        console.log({ rowSelection });
    }, [rowSelection]);


    const handleObservarClick = () => {
        for (const key in rowSelection) {
            if (rowSelection[key]) { // Verifica si el valor asociado es true
                const [codigo, observ] = key.split(','); // Descompone la clave en id y index
                observarMedicamento(codigo, observ); // Llama a miFuncion con los parámetros descompuestos
            }
            leerCatalogo()
        }
        console.log("busco")

        handleCloseDialog()
    };

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: '55px' }}>
                <MaterialReactTable
                    data={catalogo ?? []}
                    columns={columns}
                    state={{ showProgressBars: !catalogo ? true : false, rowSelection }}
                    enableRowSelection
                    positionToolbarAlertBanner='bottom'
                    getRowId={(row) => [row.codigobarras, row.observado]} //give each row a more useful id
                    onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                    localization={MRT_Localization_ES}
                    enableDensityToggle={false}
                    enableColumnFilters={false}
                    enableTableFooter={false}
                    initialState={{
                        density: 'compact',
                    }}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: '400px' } }}
                    //enablePagination={false}
                    renderTopToolbarCustomActions={({ table }) => (
                        <>
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    disabled={table.getRowModel().rows.length === 0}
                                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                    onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                    size='small'
                                >
                                    CSV
                                </Button>
                                <Button
                                    onClick={() => dialogo()}
                                    variant="contained"
                                    size='small'
                                    color='secondary'
                                >
                                    Cambiar Bloqueo
                                </Button>
                            </Box>
                            <Typography variant="body1" fontSize={24}>
                                Catalogo de Medicamentos
                            </Typography>
                        </>
                    )}
                    //memoMode="rows"
                    // globalFilterFn="any"
                    enableGlobalFilterModes //enable the user to choose between multiple search filter modes
                    globalFilterModeOptions={['fuzzy', 'startsWith']} //only allow the user to choose between fuzzy and startsWith filter modes

                    muiTableBodyCellProps={({ row }) => ({
                        sx: {
                            color:
                                row.original.observado === "1" ? "red" : "black",
                            borderColor: 'blue'

                        },
                    })}

                />
            </Container>

            <Dialog open={openDialog}
                onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography align="center">
                        {"¿ Confirma que quiere cambaiar la observacion de los medicamentos seleccionados?"}
                    </Typography>
                </DialogTitle>
                <DialogActions>
                    <Stack spacing={4} direction="row">
                        <Button
                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            No, Cancelo
                        </Button>
                        <Button
                            style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                            onClick={() => handleObservarClick()}
                            variant="contained"
                        >
                            Si, Confirmo
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    )
}
