import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import {
  Snackbar,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Divider,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Button,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Navbar from "../Navbar";
import {
  CancelPresentation,
  ConfirmationNumberOutlined,
  Verified,
  Pinch
} from "@mui/icons-material";
import { UrlApi } from "../../services/apirest";
import { enviarmails2, logEventos } from "../../services/services";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

export default function Farmacia() {
  let navigate = useNavigate();
  const [receta, setReceta] = useState("");
  const [token, setToken] = useState("");
  const [verificado, setVerificado] = useState([]);
  const [primero, setPrimero] = useState(false);
  const [medicamentos, setMedicamentos] = useState();
  const [posicion, setPosicion] = useState()    ///determina que medicamento cambiamos, o sea el nombre de la columna
  const [codigoareemplazar, setCodigoareemplazar] = useState()   ///determinamos que codigo reemplazamos el medicamento no encontrado
  const [opcionseleccionada, setOpcionseleccionada] = useState()    ///determina la opcion seleccionada de que medicamento cambiamos, o sea el nombre de la columna

  const [boton, setBoton] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("oops!!!");

  ///Dialogo
  const [openDialog, setOpendialog] = useState(false)
  const [datos, setDatos] = useState({
    codreem1: 0, reemplazo1: '', codreem2: 0, reemplazo2: '', codreem3: 0, reemplazo3: ''
  })


  useEffect(() => {
    setPrimero(true);
  }, []);


  useEffect(() => {
    const buscamedicamentos = () => {
      fetch(UrlApi + "/recetas/medicamentos/1")
        .then((data) => data.json())
        .then((data) => setMedicamentos(data));
    };
    buscamedicamentos();
  }, []);

  const handleChangesAutoCompletes = async (nombre, valor) => {
    setDatos((previo) => ({ ...previo, [nombre]: valor }));
  };

  const verificardatos = () => {
    fetch(UrlApi + "/recetas/verificatoken/" + token + "/" + localStorage.getItem("user"))
      .then((data) => data.json())
      .then((data) => setVerificado(data), setPrimero(false),
        logEventos("Farmacia", "Farmacia", "Verifica Token : " + token, localStorage.getItem("user")));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    salir();
  };

  const handleCloseDialog = () => {
    setOpendialog(!openDialog);
  };

  const salir = () => {
    navigate("/menu");
  };

  const vendido = async (p) => {
    setBoton(false);
    await fetch(UrlApi + "/recetas/" + verificado[0].recetas_id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        p === 1
          ? {
            farmacia: localStorage.getItem("user"),
            fechaventa: moment(new Date()).format("YYYY-MM-DD"),
            reemplazo1: datos.reemplazo1,
            reemplazo2: datos.reemplazo2,
            reemplazo3: datos.reemplazo3,
            codreem1: datos.codreem1,
            codreem2: datos.codreem2,
            codreem3: datos.codreem3,

          }
          : {
            farmacia: null,
            fechaventa: null,
            reemplazo1: null,
            reemplazo2: null,
            reemplazo3: null,
            codreem1: null,
            codreem2: null,
            codreem3: null,
          }
      ),
    })
      .then((res) =>
        res.status === 200
          ? [setOpen(true), setMensaje("Receta Actualizada")]
          : [
            setOpen(true),
            setMensaje("Error al actualizar la receta"),
            setBoton(true),
          ]
      )
      .catch((err) => console.log(err));
  };


  const opcionSeleccionada1 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codreem1);
  const opcionSeleccionada2 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codreem2);
  const opcionSeleccionada3 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codreem3);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });


  return (
    <div>
      <Navbar />
      <Container sx={{ marginTop: "60px", marginLeft: "10px" }}>
        <Typography variant="h5">Verificar Token de autorización</Typography>
        <Divider sx={{ borderBottomWidth: "2px", borderColor: "teal" }} />
        <Grid
          container
          spacing={1}
          sx={{ marginTop: "10px", marginLeft: "20px" }}
        >
          {/*                     <Grid item xs={2}>
                        <TextField
                            variant="outlined"
                            label="Receta Nro"
                            name="receta"
                            size="small"
                            value={receta}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => setReceta(e.target.value)}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid> */}
          <Grid item xs={2}>
            <TextField
              focused
              variant="outlined"
              label="Token"
              name="token"
              size="small"
              value={token}
              onChange={(e) => setToken(e.target.value.toUpperCase())}
              inputProps={{ maxLength: 4 }}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              variant="outlined"
              size="small"
              color="error"
              title="Verificar"
              onClick={() => verificardatos()}
              disableRipple={true}
              sx={{ border: "1px solid red", borderRadius: 1 }}
            >
              <Verified /> Verificar
            </IconButton>
          </Grid>
        </Grid>
        <Divider
          sx={{
            marginTop: "20px",
            borderBottomWidth: "2px",
            borderColor: "teal",
          }}
        />
        <Container sx={{ marginTop: "25px", fontSize: "18px", padding: "0px" }}>
          {verificado.length > 0 ? (
            <>
              {console.log(verificado)}
              <Typography variant="h6">
                Receta {verificado[0].recetas_id}
              </Typography>
              <Typography variant="h6" color="red">
                {verificado[0].fechaventa ? (
                  <>
                    Vendido el:{" "}
                    {moment(verificado[0].fechaventa).format("DD/MM/YYYY")}
                  </>
                ) : (
                  <></>
                )}
              </Typography>
              <Divider></Divider>
              <Table size="small" sx={{ marginBottom: "10px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Paciente</TableCell>
                    <TableCell>Medicamento 1</TableCell>
                    <TableCell>Cant</TableCell>
                    <TableCell>Medicamento 2</TableCell>
                    <TableCell>Cant</TableCell>
                    <TableCell>Medicamento 3</TableCell>
                    <TableCell>Cant</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {verificado[0].apellido}, {verificado[0].nombre}
                    </TableCell>
                    <TableCell>{verificado[0].medicamento1}
                      {verificado[0].medicamento1 ?
                        !verificado[0].fechaventa ?
                          <Tooltip title="Reemplazar medicamento">
                            <Pinch color="warning" sx={{ cursor: 'pointer', }} onClick={() => [setPosicion("reemplazo1"), setCodigoareemplazar("codreem1"), setOpcionseleccionada(opcionSeleccionada1), setOpendialog(true)]} />
                          </Tooltip>
                          : <></> : <></>}
                    </TableCell>
                    <TableCell>{verificado[0].cant1}</TableCell>
                    <TableCell>{verificado[0].medicamento2}
                      {verificado[0].medicamento2 ?
                        !verificado[0].fechaventa ?
                          <Tooltip title="Reemplazar medicamento">
                            <Pinch color="warning" sx={{ cursor: 'pointer', }} onClick={() => [setPosicion("reemplazo2"), setCodigoareemplazar("codreem2"), setOpcionseleccionada(opcionSeleccionada2), setOpendialog(true)]} />
                          </Tooltip>
                          : <></> : <></>}
                    </TableCell>
                    <TableCell>{verificado[0].cant2}</TableCell>
                    <TableCell>{verificado[0].medicamento3}
                      {verificado[0].medicamento3 ?
                        !verificado[0].fechaventa ?
                          <Tooltip title="Reemplazar medicamento">
                            <Pinch color="warning" sx={{ cursor: 'pointer', }} onClick={() => [setPosicion("reemplazo3"), setCodigoareemplazar("codreem3"), setOpcionseleccionada(opcionSeleccionada3), setOpendialog(true)]} />
                          </Tooltip>
                          : <></> : <></>}
                    </TableCell>
                    <TableCell>{verificado[0].cant3}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Reemplazo</b>
                    </TableCell>
                    <TableCell>{datos.reemplazo1 || verificado[0].reemplazo1}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{datos.reemplazo2 || verificado[0].reemplazo2}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{datos.reemplazo3 || verificado[0].reemplazo3}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <IconButton
                disableRipple={true}
                disabled={verificado[0].farmacia}
                sx={{
                  color: "navy",
                  backgroundColor: "lightgreen",
                  border: "1px solid navy",
                  borderRadius: 1,
                  fontSize: "18px",
                }}
                onClick={() => vendido(1)}
              >
                <ConfirmationNumberOutlined />
                Confirma Venta
              </IconButton>
              <IconButton
                disableRipple={true}
                disabled={!verificado[0].farmacia}
                sx={{
                  color: "white",
                  backgroundColor: "red",
                  border: "1px solid navy",
                  borderRadius: 1,
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
                onClick={() => vendido(0)}
              >
                <CancelPresentation />
                Cancela Venta
              </IconButton>
            </>
          ) : !primero ? (
            <Typography>
              No existe ese token, o fue utilizado anteriormente
            </Typography>
          ) : (
            <></>
          )}
        </Container>
      </Container>

      {/*Dialogo para cambiar medicamentos */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography align="left">
            Reemplazo de Medicamento
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"left"}>
            <p>Para el reemplazo de medicamento, escribí parte del nombre, elejilo de la lista, luego pulsar boton "Confirma cambio"</p>
            <p>Si no encontrás el medicamento comunicate con la empresa para habilitarlo</p>
          </DialogContentText>
          {medicamentos ?
            <Autocomplete
              size="small"
              options={medicamentos}
              getOptionLabel={(opcion) => opcion.remedios}
              getoptionselected={(opcion, valor) =>
                opcion.remedio === valor.remedio
              }
              onChange={(event, value) => [
                handleChangesAutoCompletes(
                  posicion,
                  value.remedios
                ),
                handleChangesAutoCompletes(codigoareemplazar, value.codigobarras),
              ]}
              renderInput={(params) => (
                <TextField {...params} label="Escribí parte del nombre..." />
              )}
              value={opcionseleccionada}
              filterOptions={filterOptions}
            /> : null}
        </DialogContent>
        <DialogActions>
          <Stack spacing={4} direction="row">
            <Button
              style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              variant="contained"
              onClick={() => [handleChangesAutoCompletes(posicion, ""), handleChangesAutoCompletes(codigoareemplazar, ""), handleCloseDialog()]}
            >
              {opcionseleccionada ? "Borrar reemplazo" : "Cancelar"}
            </Button>
            <Button
              style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
              onClick={handleCloseDialog}
              variant="contained"
            >
              Confirma Cambio
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>





      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        message={mensaje}
      />
    </div>
  );
}
