import { useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import Navbar from './Navbar'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { UrlApi } from '../services/apirest'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import moment from 'moment/moment';
import { PhotoCamera } from '@mui/icons-material';
import FileUploadMultiple from '../services/UploadFiles';

function Listados() {
    const [ladata, setLaData] = useState()
    const [openclose, setOpenClose] = useState(false); ///para abrir el file upload
    const [borrar, setBorrar] = useState(false); //indica si puede borrar o no los adjuntos
    const [archivo, setArchivo] = useState();

    useEffect(() => {
        buscarData()
    }, [])

    const buscarData = () => {
        fetch(UrlApi + '/recetas/todas/' + "todas")
            .then(data => data.json())
            .then(data => setLaData(data))
    }

    //// para el csv
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const csvOptions = {
        filename: 'farmaRecetas',
        fieldSeparator: ';',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
    };
    const csvExporter = new ExportToCsv(csvOptions);
    ///fin csv 


    const columns = useMemo(
        () => [
            {
                accessorKey: 'recetas_id', //access nested data with dot notation
                header: 'NroReceta',
                maxSize: 40,
            },
            {
                accessorKey: 'fecharegistro',
                header: 'Solicitado',
                size: 20,
                //custom conditional format and styling
                Cell: ({ cell }) => (
                    cell.getValue()?.toLocaleString?.('es', {
                        weekday: "short",
                        dateStyle: "short",
                        year: "numeric",
                        month: "2-digit",
                        day: "numeric"
                    })
                ),
            },
            {
                accessorKey: 'solicitante', //normal accessorKey
                header: 'Solicitó',
                size: 50
            },
            {
                accessorKey: 'empresa',
                header: 'Empresa',
                size: 50
            },
            {
                accessorKey: 'nombresucursal',
                header: 'Sucursal',
                size: 50,
            },
            {
                accessorKey: 'legajo',
                header: 'Legajo',
                size: 50,
            },
            {
                accessorKey: 'medicamento1',
                header: 'Medicamento 1',
            },
            {
                accessorKey: 'medicamento2',
                header: 'Medicamento 2',
            },
            {
                accessorKey: 'medicamento3',
                header: 'Medicamento 3',
            },
            {
                accessorKey: 'token',
                header: 'Token',
                size: 50,
            },
            {
                accessorKey: 'lotepago',
                header: 'Ltepgo',
                size: 50,
            },
            {
                accessorKey: 'op_sisven',
                header: 'OP-Sisven',
                size: 50,
            },
            {
                accessorKey: 'abonar',
                header: 'A Pagar',
                size: 50,
            },
        ],
        [],
    );

    return (
        <>
            {ladata && console.log(ladata)}
            <Navbar />
            <Container maxWidth="xl" sx={{ marginTop: "60px" }}>
                <MaterialReactTable
                    columns={columns}
                    data={ladata ?? []}
                    state={{ showProgressBars: !ladata ? true : false }}
                    localization={MRT_Localization_ES}
                    enableDensityToggle={false}
                    enableColumnFilters={false}
                    enableTableFooter={false}
                    initialState={{
                        density: 'compact',
                        columnVisibility: { medicamento1: false, medicamento2: false, medicamento3: false, diagnostico: false },
                        expanded: false,

                    }}
                    enableHiding={false}
                    enableColumnActions={false}
                    enableStickyHeader
                    muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                    //enablePagination={false}
                    muiTableBodyCellProps={({ row }) => ({
                        sx: {
                            borderColor: 'red', backgroundColor: row.original.estado === 'archivado' ? 'pink' : row.original.estado === 'rechazado' ? 'orangered' : 'none'
                        },
                    })}

                    renderTopToolbarCustomActions={({ table }) => (
                        <>
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    disabled={table.getRowModel().rows.length === 0}
                                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                    onClick={() => handleExportRows(table.getRowModel().rows)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                    size='small'
                                >
                                    CSV
                                </Button>
                            </Box>

                            <Typography variant='h5' sx={{ paddingLeft: '10px' }}>   Recetas - Todas</Typography>
                        </>
                    )}

                    renderDetailPanel={({ row }) => (
                        <Stack>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    width: '100%',
                                    border: '1px solid red',
                                    padding: '5px',
                                    marginLeft: 0,
                                }}
                                component='fieldset'
                            >
                                <legend>Detalle</legend>
                                <Typography sx={{ fontSize: 14 }}>Paciente: {row.original.paciente === '0' ? row.original.solicitante : row.original.famapellid}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Diagnostico: {row.original.diagnostico}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Observaciones solicitante: {row.original.observaciones_usuario}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Medicamento 1: {row.original.medicamento1} x {row.original.cant1}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Medicamento 2: {row.original.medicamento2} x {row.original.cant2}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Medicamento 3: {row.original.medicamento3} x {row.original.cant3}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Medico: {row.original.medico}</Typography>
                                {row.original.estado === 'rechazado' ?
                                    <Typography sx={{ fontSize: 14, color: 'red' }}>Rechazado : {row.original.motivo_rechazo} </Typography>
                                    : <Typography sx={{ fontSize: 14 }}>Autorizado por: {row.original.autoriza} / {moment(row.original.fecha_autoriza).format("DD/MM/YYYY")}</Typography>
                                }
                                <Typography sx={{ fontSize: 14, color: row.original.estado === 'archivado' ? 'red' : 'black' }}>Estado: {row.original.estado} / {row.original.controlado === 1 ? "Controlado" : "Falta Controlar"}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Pagado: {row.original.pagado === 1 ? moment(row.original.fechapago).format("DD/MM/YYYY") : "Pago Pendiente"}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Importe depo: {row.original.abonar}</Typography>
                                <Typography sx={{ fontSize: 14 }}>OP: {row.original.lotepago} * Sisven OP: {row.original.op_sisven} *</Typography>
                                <Typography sx={{ fontSize: 14 }}>Vendio Farmacia: {row.original.farmanombre}</Typography>
                                <Typography sx={{ fontSize: 14 }}>Fecha Venta: {row.original.fventa}</Typography>
                                <Stack direction="row" spacing={2}>
                                    <PhotoCamera sx={{ borderRadius: 0, color: "orange" }}
                                        onClick={() => [
                                            setBorrar(false),
                                            setOpenClose(!openclose),
                                            setArchivo(row.original.id_archivo),
                                        ]}
                                        title="Ver adjuntos"
                                    />
                                    <Typography sx={{ fontSize: 14 }}>DobleClick en la camara ver adjuntos</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    )}
                />
            </Container>
            {/*Dialogo para ver los adjuntos */}
            {openclose ? (
                <FileUploadMultiple fileId={archivo} openclose={true} borrar={borrar} />
            ) : null}
        </>
    )
}

export default Listados