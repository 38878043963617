import { UrlApi, MailApi, SmsApi } from '../services/apirest'

export function isAuthenticated() {
    return localStorage.getItem('token') && localStorage.getItem('token-expiration') > Date.now()
}

export const cuentamensajes = async () => {
    const data = await fetch(
        UrlApi + "/mensajes/" + localStorage.getItem("user") + "/count"
    );
    const mensajesdata = await data.json();
    console.log(mensajesdata)
    return mensajesdata;
};

export const enviarmails = async (receptor, asunto, texto) => {
    await fetch(MailApi + "/xemail", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            receptor: receptor,
            asunto: asunto,
            texto: texto
        })
    })
        .then((response) => response.json())
        .then((responseData) => responseData.accepted.length > 0 ? alert("Se enviaron mensajes a " + responseData.accepted.length + " destinatarios") : alert("error"))
        .catch(err => {
            console.log(err.message)
        })
}

/// envio de mails silencioso ***sin alert***
export const enviarmails2 = async (receptor, asunto, texto) => {
    await fetch(MailApi + "/xemail", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            receptor: receptor,
            asunto: asunto,
            texto: texto
        })
    })
        .then((response) => response.json())
        .then((responseData) => console.log(responseData))
        .catch(err => {
            console.log(err.message)
        })
}

///enviar manual de usuario
export const enviarmanual = async (receptor, asunto, texto) => {
    await fetch(MailApi + "/manualusuario", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            receptor: receptor,
            asunto: asunto,
            texto: texto
        })
    })
        .then((response) => response.json())
        .then((responseData) => responseData.accepted.length > 0 ? alert("Se envio manual del usuario a " + responseData.accepted.length + " destinatarios") : alert("error"))
        .catch(err => {
            console.log(err.message)
        })
}

// enviar sms por el smsgateway de sms
export const enviarsms = async (destino, texto) => {
    await fetch(SmsApi + "/sensores", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            destinationnumber: destino,
            textdecoded: texto,
            creatorid: "sistemafarmacia"
        })
    })
        .then((response) => response.json())
        .then((responseData) => console.log(responseData))
        .catch(err => {
            console.log(err.message)
        })
}

///registrar eventos en una tabla de eventos
export const logEventos = async (evento, menu, accion, usuario) => {
    await fetch(UrlApi + "/userlogs", {
        method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            evento: evento,
            menu: menu,
            accion: accion,
            user: usuario
        })
    })
        .then(res => {
            if (!res.ok) { console.log(res) };
        }
        )
        .catch(err => {
            console.log(err.message)
        })
}