import React, { useState, useEffect } from 'react';
import {
    TextField, Button, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Container, Typography, Grid, Box, Collapse, IconButton
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { UrlApi } from '../../services/apirest';
import Navbar from '../Navbar';
import RecetasModal from './RecetasModal';

function Row({ row, desde, hasta, handleOpenModal }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.legajo}</TableCell>
                <TableCell>{row.nombrepila}</TableCell>
                <TableCell>{row.apellido}</TableCell>
                <TableCell align="right">{row.total_cantidad}</TableCell>
                <TableCell align="right">{(row.total_abonar || 0).toFixed(2)}</TableCell> {/* Asegura que total_abonar sea numérico */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Medicamentos Solicitados
                            </Typography>
                            <Table size="small" aria-label="medicamentos">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Medicamento</TableCell>
                                        <TableCell>Paciente</TableCell>
                                        <TableCell align="right">Cantidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.medicamentos.map((medicamentoRow, index) => (
                                        <TableRow key={index} onClick={() => handleOpenModal(medicamentoRow.medicamento, row.solicito)} sx={{ cursor: 'pointer' }}>
                                            <TableCell component="th" scope="row">
                                                {medicamentoRow.medicamento}
                                            </TableCell>
                                            <TableCell>
                                                {medicamentoRow.paciente_nombre === 'Empleado'
                                                    ? 'Empleado'
                                                    : `${medicamentoRow.paciente_nombre} ${medicamentoRow.paciente_apellido}`}
                                            </TableCell>
                                            <TableCell align="right">
                                                {medicamentoRow.cantidad}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function Stats() {
    const [desde, setDesde] = useState('');
    const [hasta, setHasta] = useState('');
    const [medicamentosPorEmpleado, setMedicamentosPorEmpleado] = useState([]);
    const [solicito, setSolicito] = useState('');
    const [ranking, setRanking] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMedicamento, setSelectedMedicamento] = useState('');

    const fetchData = () => {
        if (desde && hasta && desde <= hasta) {
            fetchMedicamentosPorEmpleado();
            fetchRanking();
        } else {
            console.error('La fecha "Hasta" debe ser posterior o igual a la fecha "Desde"');
        }
    };

    const fetchMedicamentosPorEmpleado = () => {
        fetch(`${UrlApi}/stats/medicamentosXempleado/${desde}/${hasta}`)
            .then(response => response.json())
            .then(data => {
                const groupedData = data.reduce((acc, item) => {
                    const key = `${item.legajo}-${item.nombrepila}-${item.apellido}`;
                    if (!acc[key]) {
                        acc[key] = {
                            legajo: item.legajo,
                            nombrepila: item.nombrepila,
                            apellido: item.apellido,
                            total_cantidad: 0,
                            total_abonar: item.total_abonar, // Añade total_abonar aquí
                            medicamentos: [],
                            solicito: item.solicito
                        };
                    }
                    acc[key].medicamentos.push({
                        medicamento: item.medicamento,
                        paciente_nombre: item.paciente_nombre,
                        paciente_apellido: item.paciente_apellido,
                        cantidad: item.total_cantidad
                    });
                    acc[key].total_cantidad += item.total_cantidad;
                    return acc;
                }, {});

                // Ordenar los medicamentos por nombre
                for (let key in groupedData) {
                    groupedData[key].medicamentos.sort((a, b) => a.medicamento.localeCompare(b.medicamento));
                }

                const sortedData = Object.values(groupedData).sort((a, b) => b.total_cantidad - a.total_cantidad);
                setMedicamentosPorEmpleado(sortedData);
            })
            .catch(error => console.error('Error:', error));
    };

    const fetchRanking = () => {
        fetch(`${UrlApi}/stats/ranking_medicamentos/${desde}/${hasta}`)
            .then(response => response.json())
            .then(data => setRanking(data))
            .catch(error => console.error('Error:', error));
    };

    const filteredData = medicamentosPorEmpleado.filter(row =>
        row.legajo.toString().includes(searchTerm) ||
        row.nombrepila.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.apellido.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleOpenModal = (medicamento, solicito) => {
        setSelectedMedicamento(medicamento);
        setSolicito(solicito);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedMedicamento('');
    };

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: '55px' }}>
                <TextField
                    label="Desde"
                    type="date"
                    value={desde}
                    onChange={e => setDesde(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginRight: 2 }}
                />
                <TextField
                    label="Hasta"
                    type="date"
                    value={hasta}
                    onChange={e => setHasta(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginRight: 2 }}
                    error={!!(desde && hasta && desde > hasta)} // Asegúrate de que este valor sea booleano
                    helperText={desde && hasta && desde > hasta ? 'La fecha "Hasta" debe ser posterior o igual a la fecha "Desde"' : ''}
                />
                <Button variant="contained" onClick={fetchData}>Buscar</Button>
                <TextField
                    label="Buscar por Legajo, Nombre o Apellido"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    sx={{ marginLeft: 2, width: '300px' }}
                />
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Ranking de medicamentos solicitados</Typography>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Medicamento</TableCell>
                                        <TableCell align="right">Cantidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking.map((row) => (
                                        <TableRow key={row.medicamento}>
                                            <TableCell>{row.medicamento}</TableCell>
                                            <TableCell align="right">{row.total_cantidad}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>Medicamentos solicitados por empleado</Typography>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Legajo</TableCell>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Apellido</TableCell>
                                        <TableCell align="right">Tot Mtos</TableCell>
                                        <TableCell align="right">$Abonado</TableCell> {/* Nueva columna */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.map((row) => (
                                        <Row key={row.legajo} row={row} desde={desde} hasta={hasta} handleOpenModal={handleOpenModal} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
            <RecetasModal
                open={modalOpen}
                handleClose={handleCloseModal}
                medicamento={selectedMedicamento}
                desde={desde}
                hasta={hasta}
                solicito={solicito}
            />
        </div>
    );
}

export default Stats;