import { getConfig } from '@testing-library/react'
import React, { useEffect, useState } from 'react'
import { UrlApi } from '../../services/apirest'
import Navbar from '../Navbar'
import { Container } from '@mui/material'

export default function Config() {
    const [configs, setConfigs] = useState([])

    useEffect(() => {
        getConfigs()
    }, [])

    const getConfigs = () => {
        fetch(UrlApi + '/configs')
            .then(data => data.json())
            .then(data => setConfigs(data))
    }

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: '60px' }}>
                {configs ?
                    configs.map(config =>
                        <div key={config.id}>
                            {config.configuracion} {config.valor}
                        </div>)

                    : <></>}
            </Container>
        </div>
    )
}
