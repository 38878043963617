import {
  Card,
  CardContent,
  Container,
  Typography,
  Avatar,
  Grid,
  TextField,
  Button,
  CardHeader,
} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { UrlApi } from '../../services/apirest';
import { useNavigate } from "react-router-dom";

function InfoUser() {
  let navigate = useNavigate();
  const [wpass, setWpass] = useState("")
  const [wpassConfirm, setWpassConfirm] = useState("")
  const [correcto, setCorrecto] = useState(false)
  const [userId, setUserId] = useState("")

  useEffect(() => {
    ((wpass.length >= 6 && wpassConfirm.length >= 6) && wpass === wpassConfirm) ? setCorrecto(true) : setCorrecto(false)
  }, [wpass, wpassConfirm])

  useEffect(() => {
    obtenerUsuarios()
  }, [])

  const obtenerUsuarios = async () => {
    const data = await fetch(UrlApi + "/userdata/" + localStorage.getItem("user"))
    const userData = await data.json()
    setUserId(userData)
  }

  const modificaUsuario = (e) => {
    e.preventDefault();
    correcto ? cambiarPassword() : alert("Error al cambiar la password")
  }

  const handleVolver = () => (alert("Se cambio la password" + "\r" + "Debe volver a entrar"), navigate("/"))

  const cambiarPassword = async () => {
    await fetch(UrlApi + "/userpass/" + userId[0].id, {
      method: 'PUT', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password: wpass
      })
    })
      .then(handleVolver)
      .catch(err => { console.log(err.message) })
  }

  return (
    <div>
      <Navbar />
      <Container sx={{ marginTop: "60px" }}>
        <br />
        <Typography
          variant="h5"
          component="div"
          style={{ paddingLeft: "15px" }}
          bgcolor="navy"
          color="white"
        >
          Informacion del usuario
        </Typography>
        <Card variant="outlined">
          <CardHeader
            avatar={<Avatar sx={{ boxShadow: 5, backgroundColor: 'teal' }} >
              <ManageAccountsIcon />
            </Avatar>}
            title="Cambio de contraseña"
            subheader=""
            titleTypographyProps={{ variant: 'h6' }} />
          <CardContent>
            <TextField type="password" id="pass" label="Nueva Password" variant="outlined" color="secondary"
              onChange={(event) => { setWpass(event.target.value); }} value={wpass}
            />
            <TextField type="password" id="passconfirm" label="Confirma Password" variant="outlined" color="info"
              onChange={(event) => { setWpassConfirm(event.target.value); }} value={wpassConfirm}
            />
            {correcto ? <p style={{ color: "green" }}>Correcto</p> :
              <><p style={{ color: "red" }}>La password debe tener 6 o mas caracteres <br />y deben coincidir password y confirmacion <br />No puede colocar 123456 nuevamente </p></>}
            <Button variant="outlined" color='success' onClick={modificaUsuario}>Cambiar</Button>
          </CardContent>
        </Card>

      </Container>
    </div>
  );
}

export default InfoUser;
