import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect';
import moment from 'moment'
import Navbar from './Navbar'
import { UrlApi } from '../services/apirest';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from "react-router-dom";
import { AddAlarm, AddBox, Pause } from '@mui/icons-material';

export default function ListarRecetas() {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [recetas, setRecetas] = useState()
    const [openDialog, setOpendialog] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        buscarecetas()
    }, [])

    const buscarecetas = () => {
        fetch(UrlApi + '/recetas/' + localStorage.getItem("user"))
            .then(data => data.json())
            .then(data => setRecetas(data))
            .then(setLoading(false))
    }

    const handleCloseDialog = () => {
        setOpendialog(!openDialog)
    }

    const dialogo = (recipie) => {
        setData(recipie)
        setOpendialog(true)
    }


    const borrarReceta = () => {
        fetch(UrlApi + "/recetas/" + data.recetas_id, {
            method: 'DELETE', headers: { 'Content-Type': 'application/json' }
        })
            .then(buscarecetas, setOpendialog(false))
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: "60px" }}>
                <Stack direction="row" spacing={20} sx={{ marginBottom: '10px' }}>
                    <Typography variant="h5">
                        Mis Recetas
                    </Typography>
                    <Button variant="outlined" startIcon={<AddBox />} onClick={() => navigate("/recetas/nuevo")}>
                        Nueva
                    </Button>
                </Stack>
                {loading ? (<div> <CircularProgress color="secondary" /> </div>) : null}
                <Grid container spacing={1} direction="column" sx={{ width: isBrowser ? "400px" : null }}>
                    {recetas ? recetas.map(elem =>
                        <Grid item key={elem.recetas_id} xs={4} sx={{ paddingTop: "0px" }}>
                            <Card sx={{ border: '1px solid black', boxShadow: 10 }}>
                                <CardHeader
                                    sx={{ paddingTop: '10px', paddingBottom: 0 }}
                                    avatar={
                                        <Stack>
                                            <Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor:
                                                    elem.estado === "pendiente" ? "yellow" : elem.estado === "aprobado" ? "green" : "red",
                                                border: "2px solid black",
                                                color: elem.estado === "pendiente" ? "black" : "white",
                                                width: 90, height: 40,
                                            }}
                                                variant="rounded">
                                                {elem.recetas_id.toString().padStart(4, '0')}
                                            </Avatar>
                                            {elem.token ?
                                                <Avatar sx={{
                                                    boxShadow: 5,
                                                    backgroundColor: "black",
                                                    border: "2px solid red",
                                                    color: "white",
                                                    width: 90, height: 40,
                                                }}
                                                    variant="rounded">
                                                    {elem.token}
                                                </Avatar> : null}
                                        </Stack>}
                                    action={
                                        elem.estado === 'pendiente' || elem.estado === 'rechazado' ?
                                            <Stack>
                                                <IconButton onClick={() => navigate("/recetas/editar", { state: { elem } })}>
                                                    <EditIcon color='info' />
                                                </IconButton>
                                                <IconButton onClick={() => dialogo(elem)}>
                                                    <DeleteForeverIcon color='error' />
                                                </IconButton>
                                            </Stack>
                                            : elem.estado === 'preaprobado' ? <Pause sx={{ color: 'red' }} /> : <DoneAllIcon sx={{ color: 'green' }} />
                                    }
                                    title={"R/p " + (elem.paciente === '0' ? localStorage.getItem("apellidonombre") : `${elem.apellido}, ${elem.nombre}`)}
                                    subheader={"Fecha Receta : " + `${moment(elem.fecha_receta).format("DD/MM/YYYY")}`}
                                    titleTypographyProps={{ variant: 'h6' }}
                                //subheaderTypographyProps={{ variant: 'h7' }}
                                />
                                <CardContent sx={{ paddingTop: 0, paddingBottom: 0, border: '1px solid green', margin: '10px', boxShadow: 2, }}>
                                    <p style={{ lineHeight: "10px", fontFamily: 'Comic Sans MS' }}>{elem.medicamento1}</p>
                                    <p style={{ lineHeight: "10px", fontFamily: 'Comic Sans MS' }}>{elem.medicamento2}</p>
                                    <p style={{ lineHeight: "10px", fontFamily: 'Comic Sans MS' }}>{elem.medicamento3}</p>
                                    {elem.estado === 'rechazado' ? <p>
                                        <b>Rechazada : </b>{elem.motivo_rechazo}</p> : elem.estado === 'aprobado' ? <p align='right'><b>** Aprobada **</b></p> : elem.estado === 'preaprobado' ? <p align='right'><b>** Token en proceso **</b></p> : <></>}
                                    {elem.lotepago > 0 ? <p><b>** En proceso de pago **</b></p> : <></>}
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>
                <br></br><br></br>
            </Container>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography align="center">
                        {"¿ Confirma que quiere borrar esta receta ?"}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText textAlign={"center"}>
                        Se eliminará la receta {data.recetas_id}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={4} direction="row">
                        <Button
                            style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
                            variant="contained"
                            onClick={handleCloseDialog}
                        >
                            Cancela
                        </Button>
                        <Button
                            style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
                            onClick={() => borrarReceta()}
                            variant="contained"
                        >
                            Confirma
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </div>
    )
}
