/* export const AuthApi = "https://190.210.128.64:9001/api/"; ///implementacion de htpps con cert self-signed
export const UrlApi = "http://190.210.128.64:27020/api";
//export const UrlApi = "http://localhost:27020/api";
export const MailApi = "http://190.210.128.78:3535"
export const TelemetriaApi = "http://prod.netizar.com:3536/api"
export const titulonavbar = "DevExt"

 */

export const AuthApi = "https://farma.netizar.com:9001/api/";
export const UrlApi = "https://farma.netizar.com:9020/api";
export const MailApi = "https://farma.netizar.com:9035"
export const SmsApi = "https://sms.netizar.com/api"
export const titulonavbar = "Producción"

/*
export const AuthApi = "https://farmaauthapi.netizar.com/api/";
export const UrlApi = "https://farmaapi.netizar.com/api";
export const MailApi = "https://uservices.netizar.com"
export const SmsApi = "https://sms.netizar.com/api"
export const titulonavbar = "Producción" 
*/