import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { UrlApi } from "../services/apirest";
import { Container } from "@mui/system";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import Navbar from "./Navbar";
import FileUploadMultiple from "../services/UploadFiles";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { enviarmails, enviarsms } from "../services/services";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment/moment";
import { Archive, DeleteForever } from "@mui/icons-material";

function ListaAutorizacion() {
  let { modo } = useParams(); ///modo pasado en la url para saber si estamos en autorizacion, control o pago
  const [data, setData] = useState();
  const [openclose, setOpenClose] = useState(false); ///para abrir el file upload
  const [borrar, setBorrar] = useState(false); //indica si puede borrar o no los adjuntos
  const [archivo, setArchivo] = useState();
  const [openDialog, setOpendialog] = useState(false);
  const [fila, setFila] = useState([]);
  const [token, setToken] = useState();
  const [enviarToken, setEnviartoken] = useState(false);
  const [action, setAction] = useState();
  const [rechazo, setRechazo] = useState("");

  const [controlado, setBotonControlado] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [registro, setRegistro] = useState();

  const [trokel1, setTrokel1] = useState("");
  const [trokel2, setTrokel2] = useState("");
  const [trokel3, setTrokel3] = useState("");

  const [ver, setVer] = useState();
  const [vermedicamento, setVerMedicamento] = useState(false);

  const [recetasAnteriores, setRecetasAnteriores] = useState([]);
  const [recetasopen, setRecetasopen] = useState(false);
  const [verunareceta, setVerUnaReceta] = useState();

  const [confirmBorra, setConfirmBorra] = useState(false)

  ///genera random token con hexadecimales
  const genRanHex = (size) =>
    [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join("");

  const checkTokenExists = async (token) => {
    try {
      const response = await fetch(UrlApi + `/check-token/${token}`);
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking token:', error);
      return false;
    }
  };

  const generateUniqueToken = async () => {
    let token;
    let exists = true;

    while (exists) {
      token = genRanHex(4).toUpperCase();
      exists = await checkTokenExists(token);
    }

    return token;
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  useEffect(() => {
    traerDatos();
  }, []);

  const traerDatos = () => {
    fetch(UrlApi + "/recetas/todas/" + modo)
      .then((data) => data.json())
      .then((data) => setData(data));
  };

  //busco datos con el codigo de barra del troquel que el usuario scanea o tipea
  const buscarTroquel = (e, row, precio, etiqueta) => {
    const idToUpdate = row.recetas_id; //este es el id que tengo que buscar en el array
    // Copio el array original utilizando el método slice() en este caso es data
    const updatedArray = data.slice();
    // Encuentro el objeto que busco actualizar dentro del array utilizando su "id" correspondiente
    const indexToUpdate = updatedArray.findIndex(
      (obj) => obj.recetas_id === idToUpdate
    );
    // hacemos una peticion get de los datos del vademecum y actualizamos el objeto con los nuevos valores que vamos a utilizar
    fetch(UrlApi + "/recetas/medicamentos/1/" + e.target.value)
      .then((datas) => datas.json())
      .then((datas) =>
        datas.length > 0
          ? (updatedArray[indexToUpdate] = {
            ...updatedArray[indexToUpdate],
            [e.target.name]: e.target.value,
            [etiqueta]: datas[0].remedios,
            [precio]: datas[0].precio,
          })
          : (updatedArray[indexToUpdate] = {
            ...updatedArray[indexToUpdate],
            [etiqueta]: "No existe codigo",
            [precio]: "0",
          })
      )
      .then(() => setData(updatedArray))
      .catch((err) => console.log(err));
  };

  const ActualizaOtrosDatos = (e, row) => {
    const idToUpdate = row.recetas_id; //este es el id que tengo que buscar en el array
    // Copio el array original utilizando el método slice() en este caso es data
    const updatedArray = data.slice();
    // Encuentro el objeto que busco actualizar dentro del array utilizando su "id" correspondiente
    const indexToUpdate = updatedArray.findIndex(
      (obj) => obj.recetas_id === idToUpdate
    );
    updatedArray[indexToUpdate] = {
      ...updatedArray[indexToUpdate],
      [e.target.name]: e.target.value,
    };
    setData(updatedArray);
    e.target.name === "abonar" && e.target.value * 1 > 0
      ? setBotonControlado(true)
      : setBotonControlado(false);
  };

  const handleCloseDialog = () => {
    setOpendialog(!openDialog);
  };

  /*   const dialogo = (filacontenido, action) => {
      setFila(filacontenido);
      setToken(genRanHex(4).toUpperCase());
      setOpendialog(true);
      setAction(action);
    }; */

  const dialogo = async (filacontenido, action) => {
    setFila(filacontenido);
    const uniqueToken = await generateUniqueToken();
    setToken(uniqueToken);
    setOpendialog(true);
    setAction(action);
  };

  const dialogoborrar = (filacontenido) => {
    setFila(filacontenido);
    setConfirmBorra(true);
  };

  const vermedicamentoonclose = () => {
    setVerMedicamento(!vermedicamento);
  };

  const recetasclose = () => {
    setRecetasopen(!recetasopen);
    setVerUnaReceta(null);
  };

  const handleKeyDown = (event, row, precios, etiqueta) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      //console.log(row);
      buscarTroquel(event, row, precios, etiqueta);
    }
  };

  const aprobarReceta = async () => {
    enviarToken ?
      await fetch(UrlApi + "/recetas/" + fila.recetas_id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: token,
          estado: "aprobado",
          autoriza: localStorage.getItem("user"),
          fecha_autoriza: moment(new Date()).format("YYYY-MM-DD"),
          controlado: 0,
        }),
      })
        .then(
          traerDatos,
          setOpendialog(false),
          enviarmails(
            fila.email,
            "Aviso de Recetas medicamentos autorizados",
            `<p>Estimada/o <b>${fila.solicitante}</b></p>
                    <p>Se autorizo la receta nro ${fila.recetas_id} con el token de autorizacion <b>${token} </b></p>
                <p><b><u>Medicamentos autorizados</b></u></p>
                <p style="margin: 0px" >1.- ${fila.medicamento1} x Cant: ${fila.cant1}</p>
                <p style="margin: 0px" >2.- ${fila.medicamento2} x Cant: ${fila.cant2}</p>
                <p style="margin: 0px" >3.- ${fila.medicamento3} x Cant: ${fila.cant3}</p>`
          ),
          enviarsms(
            fila.telefonomovil,
            "Hola " +
            fila.solicitante +
            ", Te aprobaron la receta " +
            fila.recetas_id +
            " con el token : " +
            token
          )
        )
        .catch((error) => {
          console.error("There was an error!", error);
        })
      :
      ///no enviar token preautorizados por medicina labolral
      await fetch(UrlApi + "/recetas/" + fila.recetas_id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          estado: "preaprobado",
          preautoriza: localStorage.getItem("user"),
          fecha_preautoriza: moment(new Date()).format("YYYY-MM-DD"),
        }),
      })
        .then(
          traerDatos,
          setOpendialog(false),
          enviarmails(
            "rrhh@hipergas.com",
            "Aviso de Recetas medicamentos Pre-autorizados",
            `     <p>Se pre-autorizo la receta nro ${fila.recetas_id} </b></p>
                  <p><b><u>Medicamentos autorizados</b></u></p>
                  <p style="margin: 0px" >1.- ${fila.medicamento1} x Cant: ${fila.cant1}</p>
                  <p style="margin: 0px" >2.- ${fila.medicamento2} x Cant: ${fila.cant2}</p>
                  <p style="margin: 0px" >3.- ${fila.medicamento3} x Cant: ${fila.cant3}</p>`
          ))
        .catch((error) => {
          console.error("There was an error!", error);
        })

  };

  const handleControlado = async (linea) => {
    await setRegistro(
      data.find((item) => item.recetas_id === linea.recetas_id)
    );
    setConfirmOpen(true);
  };

  const fetchControlado = async () => {
    await fetch(UrlApi + "/recetas/" + registro.recetas_id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        precio1: registro.precio1,
        precio2: registro.precio2,
        precio3: registro.precio3,
        troquel1: registro.troquel1,
        troquel2: registro.troquel2,
        troquel3: registro.troquel3,
        abonar: registro.abonar,
        controlado: 1,
      }),
    })
      .then(() => setRegistro())
      .then(() => traerDatos())
      .then(() => setTrokel1(""))
      .then(() => setTrokel2(""))
      .then(() => setTrokel3(""))
      .catch((err) => console.log(err));
  };

  const rechazarReceta = async () => {
    await fetch(UrlApi + "/recetas/" + fila.recetas_id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: "rechazado",
        motivo_rechazo: rechazo,
      }),
    })
      .then(
        traerDatos,
        setOpendialog(false),
        enviarmails(
          fila.email,
          "RECHAZADO - Aviso de Recetas medicamentos.",
          `<p>Estimada/o <b>${fila.solicitante}</b></p>
                    <p>Se ha rechazado la receta nro #<b>${fila.recetas_id}</b></p>
                    <p><b><u>Ref Medicamentos rechazados</b></u></p>
                    <p style="margin: 0px" >1.- ${fila.medicamento1} x Cant: ${fila.cant1}</p>
                    <p style="margin: 0px" >2.- ${fila.medicamento2} x Cant: ${fila.cant2}</p>
                    <p style="margin: 0px" >3.- ${fila.medicamento3} x Cant: ${fila.cant3}</p>
                    <p>Motivo rechazo: ${rechazo} </p>
                    <p>Por favor realizar las acciones necesarias para su autorización.</p>`
        ),
        enviarsms(
          fila.telefonomovil,
          "Hola " +
          fila.solicitante +
          ", Te informamos el rechazo de la receta " +
          fila.recetas_id +
          ". Motivo rechazo: " +
          rechazo
        )
      )
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const verestareceta = (cualreceta) => {
    fetch(UrlApi + "/recetas/unareceta/" + cualreceta)
      .then((data) => data.json())
      .then((data) => setVerUnaReceta(data), setRecetasopen(true));
  };

  const vermedicamentos = async (codbarra, solicitante) => {
    if (!codbarra) {
      return;
    }
    setVerMedicamento(true);

    try {
      await fetch(UrlApi + "/catalogo/" + codbarra)
        .then((data) => data.json())
        .then((data) => setVer(data));
    } catch (error) {
      console.log(error);
    }

    try {
      await fetch(
        UrlApi +
        `/recetas/medicamentos/3/?solicitante=${solicitante}&codigobarras=${codbarra}`
      )
        .then((data) => data.json())
        .then((data) => setRecetasAnteriores(data));
    } catch (error) {
      console.log(error);
    }
    //ver && console.log(ver);
  };

  ////Archiva receta, antes las borraba pero pidieron que no, solo le cambia estado a archivado
  const borrarReceta = async () => {
    await fetch(UrlApi + "/recetas/" + fila.recetas_id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        estado: "archivado",
        controlado: 2
      }),
    })
      .then(
        traerDatos,
        setConfirmBorra(false)
        /* enviarmails(
          fila.email,
          "Aviso de Recetas medicamentos CANCELADA",
          `<p>Estimada/o <b>${fila.solicitante}</b></p>
                    <p>Se Cancelo la receta nro <b>${fila.recetas_id}</b></p>
                    <p><b> Por no realizar la accion correspondiente solicitada : </b> ${fila.motivo_rechazo}</p>`
        ),
        enviarsms(
          fila.telefonomovil,
          "Hola " +
          fila.solicitante +
          ", Te cancelaron la receta " +
          fila.recetas_id +
          " por no haber realizado la accion que te solicitaron. Saludos"
        ) */
      )
      .catch(error => {
        console.error('There was an error!', error)
      })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "recetas_id", //access nested data with dot notation
        header: "NroReceta",
        maxSize: 40,
      },
      {
        accessorKey: "fecharegistro",
        header: "Solicitado",
        size: 20,
        //custom conditional format and styling
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString?.("es", {
            weekday: "short",
            dateStyle: "short",
            year: "numeric",
            month: "2-digit",
            day: "numeric",
          }),
      },
      {
        accessorKey: "solicitante", //normal accessorKey
        header: "Solicitó",
        size: 50,
      },
      {
        accessorKey: "empresa",
        header: "Empresa",
        size: 50,
      },
      {
        accessorKey: "nombresucursal",
        header: "Sucursal",
      },
      {
        accessorKey: "legajo",
        header: "Legajo",
        size: 10,
      },
      {
        accessorKey: "medicamento1",
        header: "Medicamento 1",
      },
      {
        accessorKey: "medicamento2",
        header: "Medicamento 2",
      },
      {
        accessorKey: "medicamento3",
        header: "Medicamento 3",
      },
      {
        accessorKey: "obrasocial",
        header: "O.Social",
        size: 10,
      },
      {
        accessorKey: "token",
        header: "Token",
        size: 50,
      },
    ],
    []
  );

  const csvOptions = {
    filename: "farmaRecetas",
    fieldSeparator: ";",
    quoteStrings: "",
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    //headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <div>
      <Navbar />
      <Container maxWidth="xl" sx={{ marginTop: "60px" }}>

        <MaterialReactTable
          columns={columns}
          data={data ?? []}
          state={{ showProgressBars: !data ? true : false }}
          localization={MRT_Localization_ES}
          enableDensityToggle={false}
          enableColumnFilters={false}
          enableTableFooter={false}
          //autoResetPageIndex={false}
          //paginateExpandedRows={true}
          initialState={{
            density: "compact",
            columnVisibility: {
              medicamento1: false,
              medicamento2: false,
              medicamento3: false,
              diagnostico: false,
            },
            expanded: false,
          }}
          enableHiding={false}
          enableColumnActions={false}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
          enablePagination={false}

          //enableGlobalFilterModes //enable the user to choose between multiple search filter modes
          //globalFilterModeOptions={['fuzzy', 'startsWith']} //only allow the user to choose between fuzzy and startsWith filter modes
          globalFilterFn="contains"
          muiTableBodyCellProps={({ row }) => ({
            sx: {
              background:
                row.original.estado === "rechazado"
                  ? "red"
                  : row.original.estado === "aprobado"
                    ? "green"
                    : row.original.estado === "preaprobado"
                      ? "violet"
                      : "none",
              color: row.original.estado === "pendiente" || row.original.estado === "preaprobado" ? "black" : "white",
              borderColor: "red",
            },
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  disabled={table.getRowModel().rows.length === 0}
                  //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                  onClick={() => handleExportRows(table.getRowModel().rows)}
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  size="small"
                >
                  CSV
                </Button>
              </Box>
              <Typography variant="poster" fontSize={24}>
                Recetas -{" "}
                {modo === "autorizar"
                  ? "Autorizacion"
                  : modo === "controlar"
                    ? "Control"
                    : "Pago"}
              </Typography>
            </>
          )}
          renderDetailPanel={({ row }) => (
            <Stack>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  width: "100%",
                  border: row.original.obrasocial === 'otro' ? "2px solid red" : "1px solid green",
                  padding: "5px",
                  marginLeft: 0,
                }}
                component="fieldset"
              >
                <legend>Detalle - O.Social : {row.original.obrasocial}</legend>
                <Typography sx={{ fontSize: 14 }}>
                  Paciente:{" "}
                  {row.original.paciente === "0"
                    ? row.original.solicitante
                    : row.original.famapellid}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  Diagnostico: {row.original.diagnostico}
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  Observaciones solicitante:{" "}
                  {row.original.observaciones_usuario}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    vermedicamentos(
                      row.original.codigo1,
                      row.original.solicito
                    )
                  }
                >
                  Medicamento 1: {row.original.medicamento1} {"/Cant: "}
                  {row.original.cant1} / DD: {row.original.dd1}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    vermedicamentos(
                      row.original.codigo2,
                      row.original.solicito
                    )
                  }
                >
                  Medicamento 2: {row.original.medicamento2} {"/Cant: "}
                  {row.original.cant2} / DD: {row.original.dd2}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    vermedicamentos(
                      row.original.codigo3,
                      row.original.solicito
                    )
                  }
                >
                  Medicamento 3: {row.original.medicamento3} {"/Cant: "}
                  {row.original.cant3} / DD: {row.original.dd3}
                </Typography>


                {/* /////// reemplazos de medicamentos */}
                {row.original.reemplazo1 ?
                  <>
                    <Typography
                      sx={{
                        fontSize: 14,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        vermedicamentos(
                          row.original.codreem1,
                          row.original.solicito
                        )
                      }
                    >
                      Reemplazo 1: {row.original.reemplazo1}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        vermedicamentos(
                          row.original.codreem2,
                          row.original.solicito
                        )
                      }
                    >
                      Reemplazo 2: {row.original.reemplazo2}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        vermedicamentos(
                          row.original.codreem3,
                          row.original.solicito
                        )
                      }
                    >
                      Reemplazo 3: {row.original.reemplazo3}
                    </Typography>
                  </>
                  : <></>}

                <Typography sx={{ fontSize: 14 }}>
                  Medico: {row.original.medico}
                </Typography>
                {row.original.motivo_rechazo && !row.original.token ? (
                  <Typography sx={{ fontSize: 14 }}>
                    Rechazado por: {row.original.motivo_rechazo}
                  </Typography>
                ) : (
                  <> </>
                )}
                <Box
                  sx={{
                    border: "1px solid teal",
                    padding: "1px",
                    width: "50%",
                  }}
                  component="fieldset"
                >
                  <legend>Acciones</legend>
                  <IconButton
                    sx={{ borderRadius: 0, color: "teal" }}
                    onClick={() => [
                      setBorrar(false),
                      setOpenClose(!openclose),
                      setArchivo(row.original.id_archivo),
                    ]}
                    title="Ver adjuntos"
                  >
                    <AddAPhotoIcon />
                  </IconButton>
                  <IconButton
                    disabled={row.original.token ? true : false}
                    onClick={() => dialogo(row.original, 1)}
                    title="Pre-Autorizar"
                  >
                    <ThumbUpAltIcon
                      sx={{
                        color: row.original.token ? "lightgrey" : "green",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    disabled={row.original.token ? true : false}
                    onClick={() => dialogo(row.original, 2)}
                    title="Rechazar"
                  >
                    <ThumbDownAltIcon
                      sx={{ color: row.original.token ? "lightgrey" : "red" }}
                    />
                  </IconButton>
                  {localStorage.getItem("role") === 'admin' ?
                    <>
                      {row.original.estado === 'preaprobado' ?
                        <IconButton
                          onClick={() => (setEnviartoken(true), dialogo(row.original, 1))}
                          title="Autorizar / Enviar token"
                        >
                          <SpellcheckIcon
                            sx={{ color: "teal" }}
                          />
                        </IconButton>
                        :
                        <></>}
                      {row.original.estado === 'rechazado' ?
                        <IconButton
                          onClick={() => dialogoborrar(row.original)}
                          title="Borrar"
                        >
                          <Archive
                            sx={{ color: "brown" }}
                          />
                        </IconButton>
                        : <></>}
                    </>
                    : <></>}
                </Box>
                {modo === "controlar" ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: 14,
                        backgroundColor: row.original.farmanombre
                          ? "#03fc39"
                          : "#d44a53",
                        border: "1px solid black",
                      }}
                    >
                      Farmacia:{" "}
                      {row.original.farmanombre
                        ? row.original.farmanombre
                        : "No se ha informado venta"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        backgroundColor: row.original.farmanombre
                          ? "#03fc39"
                          : "#d44a53",
                      }}
                    >
                      Vendido el: {row.original.fventa}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </Box>
              {modo === "controlar" ? (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    width: "100%",
                    border: "1px solid teal",
                    padding: "5px",
                    marginLeft: 0,
                  }}
                  component="fieldset"
                >
                  <legend>Control</legend>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Troquel 1"
                    name="troquel1"
                    value={trokel1}
                    onChange={(e) => setTrokel1(e.target.value)}
                    onKeyDown={(e) =>
                      handleKeyDown(e, row.original, "precio1", "label1")
                    }
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Troquel 2"
                    name="troquel2"
                    value={trokel2}
                    onChange={(e) => setTrokel2(e.target.value)}
                    onKeyDown={(e) =>
                      handleKeyDown(e, row.original, "precio2", "label2")
                    }
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Troquel 3"
                    name="troquel3"
                    value={trokel3}
                    onChange={(e) => setTrokel3(e.target.value)}
                    onKeyDown={(e) =>
                      handleKeyDown(e, row.original, "precio3", "label3")
                    }
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    label="A Abonar"
                    name="abonar"
                    type="number"
                    onChange={(e) => ActualizaOtrosDatos(e, row.original)}
                  />

                  <InputLabel
                    sx={{
                      color:
                        row.original.codigo1 === row.original.troquel1 || row.original.codreem1 === row.original.troquel1
                          ? "green"
                          : "red",
                      fontSize: 12,
                    }}
                  >
                    {row.original.label1 || ""}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      color:
                        row.original.codigo2 === row.original.troquel2 || row.original.codreem2 === row.original.troquel2
                          ? "green"
                          : "red",
                      fontSize: 12,
                    }}
                  >
                    {row.original.label2 || ""}
                  </InputLabel>
                  <InputLabel
                    sx={{
                      color:
                        row.original.codigo3 === row.original.troquel3 || row.original.codreem3 === row.original.troquel3
                          ? "green"
                          : "red",
                      fontSize: 12,
                    }}
                  >
                    {row.original.label3 || ""}
                  </InputLabel>
                  <> </>

                  <InputLabel sx={{ fontSize: 12 }}>
                    $ {row.original.precio1 || ""}- Total x{" "}
                    {row.original.cant1} ={" "}
                    {row.original.precio1 * row.original.cant1 || 0}
                  </InputLabel>
                  <InputLabel sx={{ fontSize: 12 }}>
                    $ {row.original.precio2 || ""}- Total x{" "}
                    {row.original.cant2} ={" "}
                    {row.original.precio2 * row.original.cant2 || 0}
                  </InputLabel>
                  <InputLabel sx={{ fontSize: 12 }}>
                    $ {row.original.precio3 || ""}- Total x{" "}
                    {row.original.cant3} ={" "}
                    {row.original.precio3 * row.original.cant3 || 0}
                  </InputLabel>
                  <IconButton
                    disabled={!controlado}
                    onClick={() => {
                      handleControlado(row.original);
                    }}
                    sx={{
                      fontSize: 14,
                      border: "1px solid green",
                      borderRadius: 1,
                      color: "green",
                      padding: "5px",
                    }}
                  >
                    <DoneAllIcon /> Controlado OK
                  </IconButton>

                  {/*  <TextField
                                            variant="outlined"
                                            size='small'
                                            label='Pagado 1'
                                            name='pagado1'
                                            onChange={e => hant(e, row.original, 'precio3', 'label3')} /> */}
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          )}
        />
      </Container>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography align="center">
            {action === 1 ? "¿ Autoriza esta receta ?" : "Rechaza esta receta"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            Se {action === 1 ? "autorizará" : "rechazará"} la receta{" "}
            <b>{fila.recetas_id}</b> <br></br>
            del solicitante <b>{fila.solicitante}</b>
          </DialogContentText>
          {action === 2 ? (
            <TextField
              variant="standard"
              label="Motivo"
              onChange={(e) => setRechazo(e.target.value)}
            >
              Motivo
            </TextField>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Stack spacing={4} direction="row">
            <Button
              style={{ backgroundColor: "#d32f2f", color: "#ffffff" }}
              variant="contained"
              onClick={handleCloseDialog}
            >
              Cancela
            </Button>
            <Button
              style={{ backgroundColor: "#2e7d32", color: "#ffffff" }}
              onClick={() =>
                action === 1 ? aprobarReceta() : rechazarReceta()
              }
              variant="contained"
            >
              Confirma
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title="Control de recetas"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => fetchControlado()}
      >
        Seguro de haber controlado todo?
      </ConfirmDialog>

      <ConfirmDialog
        title="Control de recetas - Archivar"
        open={confirmBorra}
        setOpen={setConfirmBorra}
        onConfirm={() => borrarReceta()}
      >
        Seguro de achivar esta receta : {fila.recetas_id} ?
      </ConfirmDialog>

      <Dialog
        open={vermedicamento}
        onClose={vermedicamentoonclose}
        PaperProps={{ sx: { width: "80%", height: "80%" } }}
      >
        <DialogTitle
          sx={{ padding: 1, backgroundColor: "teal", color: "white" }}
        >
          Ficha del medicamento
        </DialogTitle>
        <DialogContent sx={{ padding: "10px" }}>
          {ver ? (
            <>
              <Typography>Nombre : {ver[0].nombre}</Typography>
              <Typography>Presentacion : {ver[0].presentacion}</Typography>
              <Typography>Laboratorio : {ver[0].laboratorio}</Typography>
              <Typography>Precio : {ver[0].precio}</Typography>
              <Typography>Vigencia Precio : {ver[0].fechavigencia}</Typography>
              <Typography>Tipo de venta : {ver[0].TipoVta}</Typography>
              <Typography>Origen medicamento : {ver[0].origen}</Typography>
              <Typography
                sx={{ padding: 1, backgroundColor: "teal", color: "white" }}
              >
                Recetas anteriores para este medicamento
              </Typography>

              <Table
                sx={{ minWidth: 400 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead
                  sx={{
                    border: "1px solid black",
                    backgroundColor: "lightgrey",
                  }}
                >
                  <TableRow>
                    <TableCell align="right">Receta#</TableCell>
                    <TableCell align="right">FechaReceta</TableCell>
                    <TableCell align="right">Paciente</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Estado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recetasAnteriores
                    ? recetasAnteriores.map((row) => (
                      <TableRow
                        key={row.recetas_id}
                        hover
                        sx={{ border: 1, cursor: "pointer" }}
                        onClick={() => verestareceta(row.recetas_id)}
                      >
                        <TableCell component="th" scope="row" width="5">
                          {row.recetas_id}
                        </TableCell>
                        <TableCell align="right" width="20">
                          {row.fechareceta}
                        </TableCell>
                        <TableCell align="right" width="20">
                          {row.paciente}
                        </TableCell>
                        <TableCell align="right" width="10">
                          {row.Cantidad}
                        </TableCell>
                        <TableCell align="right">{row.estado}</TableCell>
                      </TableRow>
                    ))
                    : "No hay recetas anteriores"}
                </TableBody>
              </Table>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog
        open={recetasopen}
        onClose={recetasclose}
        PaperProps={{ sx: { width: "80%", height: "80%" } }}
      >
        {verunareceta ? (
          <>
            <DialogTitle>Receta :{verunareceta[0].recetas_id}
              - {verunareceta[0].estado.charAt(0).toUpperCase() + verunareceta[0].estado.slice(1)}</DialogTitle>
            <DialogContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Medicamento</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">DosisDiaria</TableCell>
                    <TableCell align="right">Precio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{verunareceta[0].medicamento1}</TableCell>
                    <TableCell>{verunareceta[0].cant1}</TableCell>
                    <TableCell>{verunareceta[0].dd1}</TableCell>
                    <TableCell>{verunareceta[0].precio1}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{verunareceta[0].medicamento2}</TableCell>
                    <TableCell>{verunareceta[0].cant2}</TableCell>
                    <TableCell>{verunareceta[0].dd2}</TableCell>
                    <TableCell>{verunareceta[0].precio2}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{verunareceta[0].medicamento3}</TableCell>
                    <TableCell>{verunareceta[0].cant3}</TableCell>
                    <TableCell>{verunareceta[0].dd3}</TableCell>
                    <TableCell>{verunareceta[0].precio3}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography>Medico : {verunareceta[0].medico}</Typography>
              <Typography>
                Diagnostico : {verunareceta[0].diagnostico}
              </Typography>
              <Typography>
                Autoriza : {verunareceta[0].autoriza ? verunareceta[0].autoriza + " el " + moment(verunareceta[0].fecha_autoriza).format("DD/MM/YYYY") : null}
              </Typography>
              <Typography>
                Motivo Rechazo : {verunareceta[0].motivo_rechazo}
              </Typography>
              <Divider />
              <IconButton
                sx={{ borderRadius: 0, color: "teal" }}
                onClick={() => [
                  setBorrar(false),
                  setOpenClose(!openclose),
                  setArchivo(verunareceta[0].id_archivo),
                ]}
                title="Ver adjuntos"
              >
                <AddAPhotoIcon /> Ver Adjuntos
              </IconButton>
            </DialogContent>
          </>
        ) : null}
      </Dialog>

      {/*Dialogo para ver los adjuntos */}
      {openclose ? (
        <FileUploadMultiple fileId={archivo} openclose={true} borrar={borrar} />
      ) : null}
    </div>
  );
}

export default ListaAutorizacion;
