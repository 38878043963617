import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { UrlApi } from '../../services/apirest';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function RecetasModal({ open, handleClose, medicamento, desde, hasta, solicito }) {
    const [recetas, setRecetas] = useState([]);

    useEffect(() => {
        if (open) {
            fetch(`${UrlApi}/stats/detalle_recetas/${medicamento}/${solicito}/${desde}/${hasta}`)
                .then(response => response.json())
                .then(data => setRecetas(data))
                .catch(error => console.error('Error:', error));
        }
    }, [open, medicamento, desde, hasta, solicito]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Detalle de Recetas para {medicamento}
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nro Receta</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Paciente</TableCell>
                                <TableCell>Cant</TableCell>
                                <TableCell>Fecha de Solicitud</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recetas.map((receta) => (
                                <TableRow key={receta.recetas_id}>
                                    <TableCell>{receta.recetas_id}</TableCell>
                                    <TableCell>{receta.estado}</TableCell>
                                    <TableCell>{receta.paciente_nombre} {receta.paciente_apellido}</TableCell>
                                    <TableCell>{receta.cantidad}</TableCell>
                                    <TableCell>{new Date(receta.fecha_registro).toLocaleDateString('es-ES', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                    })}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
    );
}

export default RecetasModal;