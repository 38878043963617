import { isBrowser } from "react-device-detect";
import {
  Autocomplete,
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import "moment/locale/es";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import FileUploadMultiple from "../services/UploadFiles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { v4 as uuidv4 } from "uuid";
import { UrlApi } from "../services/apirest";
import { useNavigate, useLocation } from "react-router-dom";
import { enviarmails2, logEventos } from "../services/services";
import { createFilterOptions } from "@mui/material/Autocomplete";

export default function Recetas() {
  let { modo } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const [cantArchivos, setCantArchivos] = useState([]);
  const [openclose, setOpenClose] = useState(false); ///para abrir el file upload
  const [borrar, setBorrar] = useState(true) /// seteamos true para que se pueda adjuntar y borrar archivos
  const [datos, setDatos] = useState(
    modo === "editar"
      ? {
        id_archivo: location.state.elem.id_archivo,
        fecha_receta: moment(location.state.elem.fecha_receta).format("YYYY-MM-DD"),
        paciente: location.state.elem.paciente,
        medicamento1: location.state.elem.medicamento1,
        cant1: location.state.elem.cant1,
        dd1: location.state.elem.dd1,
        codigo1: location.state.elem.codigo1,
        medicamento2: location.state.elem.medicamento2,
        cant2: location.state.elem.cant2,
        dd2: location.state.elem.dd2,
        codigo2: location.state.elem.codigo2,
        medicamento3: location.state.elem.medicamento3,
        cant3: location.state.elem.cant3,
        dd3: location.state.elem.dd3,
        codigo3: location.state.elem.codigo3,
        diagnostico: location.state.elem.diagnostico,
        medico: location.state.elem.medico,
        observaciones_usuario: location.state.elem.observaciones_usuario,
        estado: "pendiente", //lo piso por si fue rechazado anteriormente
      }
      : {
        id_archivo: uuidv4(),
        fecha_receta: moment(new Date()).format("YYYY-MM-DD"),
        paciente: 0,
        medicamento1: "",
        cant1: 1,
        dd1: 0,
        codigo1: "",
        medicamento2: "",
        cant2: 1,
        dd2: 0,
        codigo2: "",
        medicamento3: "",
        cant3: 1,
        dd3: 0,
        codigo3: "",
        diagnostico: "",
        medico: "",
        observaciones_usuario: "",
        solicito: localStorage.getItem("user"),
        estado: "pendiente",
      }
  );

  const [pacientes, setPacientes] = useState();
  const [medicamentos, setMedicamentos] = useState();
  const [boton, setBoton] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("oops!!!");
  const [salgo, setSalgo] = useState(true)


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setBoton(!boton);
    if (salgo) salir()
  };

  useEffect(() => {
    logEventos("Recetas", "Recetas", "Entrada menu recetas", localStorage.getItem("user"))
    localStorage.setItem("foto", '0')
  }, [])

  useEffect(() => {
    const buscapacientes = () => {
      fetch(UrlApi + "/pacientes/" + localStorage.getItem("id_legajo"))
        .then((data) => data.json())
        .then((data) => setPacientes(data));
    };
    buscapacientes();
  }, []);

  useEffect(() => {
    buscaarchivos();
  }, []);

  const buscaarchivos = async () => {
    await fetch(UrlApi + "/recetas/archivo/" + datos.id_archivo)
      .then((data) => data.json())
      .then((data) => setCantArchivos(data));
  };

  useEffect(() => {
    const buscamedicamentos = () => {
      fetch(UrlApi + "/recetas/medicamentos/1")
        .then((data) => data.json())
        .then((data) => setMedicamentos(data));
    };
    buscamedicamentos();
  }, []);

  const handleChanges = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangesAutoCompletes = async (nombre, valor) => {
    setDatos((previo) => ({ ...previo, [nombre]: valor }));
  };

  const salir = () => {
    navigate("/menu");
  };

  const handleAction = async (e) => {
    e.preventDefault();
    setBoton(true);
    if (!datos.medicamento1.length > 0) { setMensaje("Falta poner medicamento"); setOpen(true); setSalgo(false); return }
    if (!datos.diagnostico > 0) { setOpen(true); setMensaje("Falta diagnostico"); setSalgo(false); return }
    if (modo !== "editar") {
      if (localStorage.getItem("foto") !== '1') {
        setOpen(true);
        setMensaje("¡Falta foto receta!");
        setSalgo(false);
        return;
      }
    }
    //return
    /// si el modo es edita paso los props recibidos a travez de location.state
    modo === "editar"
      ? await fetch(UrlApi + "/recetas/" + location.state.elem.recetas_id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datos),
      })
        .then((res) =>
          res.status === 200
            ? [setOpen(true), setMensaje("Receta Actualizada"),
            logEventos("Actualiza Receta", "Recetas", "Actualiza receta", localStorage.getItem("user"))]
            : [
              setOpen(true),
              setMensaje("Error al actualizar la receta"),
              setBoton(false),
            ]
        )
        .then(
          enviarmails2(
            "medicinalaboral@hipergas.com",
            "Aviso de Recetas para autorizar (Modificacion)",
            `<p>Estimada/o <b>Medicina Laboral</b></p>
            <p>Se ha modificado una receta para autorizar. </b></p>
            <p>Por favor realizar la accion correspondiente.</p>`
          )
        )
        .catch((err) => console.log(err))
      :

      await fetch(UrlApi + "/recetas", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(datos),
      })
        .then((res) =>
          res.status === 200
            ? [setOpen(true), setMensaje("Receta registrada"), logEventos("Nueva Receta", "Recetas", "Nueva receta", localStorage.getItem("user"))]
            : [
              setOpen(true),
              setMensaje("Error al registrar la receta"),
              setBoton(false),
            ]
        )
        .then(
          enviarmails2(
            "medicinalaboral@hipergas.com",
            "Aviso de Recetas para autorizar (Nueva)",
            `<p>Estimada/o <b>Medicina Laboral</b></p>
                       <p>Se recibio una receta para autorizar. </b></p>
                       <p>Por favor realizar la accion correspondiente.</p>`
          )
        )
        .then(setSalgo(true))
        .catch((err) => console.log(err));
  };

  const opcionSeleccionada1 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codigo1);
  const opcionSeleccionada2 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codigo2);
  const opcionSeleccionada3 =
    medicamentos &&
    medicamentos.find((opcion) => opcion.codigobarras === datos.codigo3);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  return (
    <div>
      <Navbar />
      <Container sx={{ marginLeft: "0px", marginTop: "60px" }}>
        {datos && medicamentos ? (
          <>
            <Grid>
              <Typography
                variant="h5"
                sx={{ color: "white", backgroundColor: "navy" }}
              >
                {modo === "editar" ? " Editando Solicitud" : " Nueva Solicitud"}
              </Typography>
            </Grid>

            <Grid
              container
              sx={{ width: isBrowser ? "400px" : null, marginLeft: "0px" }}
            >
              <Grid item xs={5}>
                <TextField
                  name="fecha_receta"
                  required
                  label="Fecha de Receta"
                  value={datos.fecha_receta}
                  onChange={handleChanges}
                  type="date"
                  size="small"
                  fullWidth
                  inputProps={{
                    max: moment(new Date()).format("YYYY-MM-DD"),
                  }}
                  sx={{ marginBottom: "20px", marginTop: "10px" }}
                />
              </Grid>
              <Grid item xs={7}>
                {pacientes && (
                  <TextField
                    name="paciente"
                    select
                    required
                    margin="dense"
                    size="small"
                    fullWidth
                    onChange={handleChanges}
                    label="Paciente"
                    value={datos.paciente}
                    defaultValue=""
                  >
                    {pacientes.map((elemento, index) => (
                      <MenuItem key={index} value={elemento.id_familiar}>
                        {elemento.apellido} {elemento.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="column"
              sx={{ width: isBrowser ? "400px" : null, marginLeft: "0px" }}
            >
              <Grid container direction="row" sx={{ marginBottom: "5px" }}>
                <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    options={medicamentos}
                    getOptionLabel={(opcion) => opcion.remedios}
                    getOptionSelected={(opcion, valor) =>
                      opcion.remedio === valor.remedio
                    }
                    onChange={(event, value) => [
                      handleChangesAutoCompletes(
                        "medicamento1",
                        value.remedios
                      ),
                      handleChangesAutoCompletes("codigo1", value.codigobarras),
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Medicamento 1" />
                    )}
                    value={opcionSeleccionada1}
                    filterOptions={filterOptions}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="Cant."
                    name="cant1"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.cant1}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 1 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="DosisDia"
                    name="dd1"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.dd1}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={{ marginBottom: "5px" }}>
                <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    options={medicamentos}
                    getOptionLabel={(opcion) => opcion.remedios}
                    getOptionSelected={(opcion, valor) =>
                      opcion.remedio === valor.remedio
                    }
                    onChange={(event, value) => [
                      handleChangesAutoCompletes(
                        "medicamento2",
                        value.remedios
                      ),
                      handleChangesAutoCompletes("codigo2", value.codigobarras),
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Medicamento 2" />
                    )}
                    value={opcionSeleccionada2}
                    filterOptions={filterOptions}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="Cant."
                    name="cant2"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.cant2}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 1 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="DosisDia"
                    name="dd2"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.dd2}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" sx={{ marginBottom: "5px" }}>
                <Grid item xs={8}>
                  <Autocomplete
                    size="small"
                    options={medicamentos}
                    getOptionLabel={(opcion) => opcion.remedios}
                    getOptionSelected={(opcion, valor) =>
                      opcion.remedio === valor.remedio
                    }
                    onChange={(event, value) => [
                      handleChangesAutoCompletes(
                        "medicamento3",
                        value.remedios
                      ),
                      handleChangesAutoCompletes("codigo3", value.codigobarras),
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Medicamento 3" />
                    )}
                    value={opcionSeleccionada3}
                    filterOptions={filterOptions}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="Cant."
                    name="cant3"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.cant3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 1 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="DosisDia"
                    name="dd3"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.dd3}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ marginTop: "5px" }}>
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    label="Diagnostico"
                    name="diagnostico"
                    size="small"
                    fullWidth
                    multiline
                    maxRows={2}
                    value={datos.diagnostico}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="MatMed"
                    name="medico"
                    size="small"
                    fullWidth
                    type={isBrowser ? "text" : "number"}
                    value={datos.medico}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ marginTop: "3px" }}>
                <Grid item xs={10}>
                  <TextField
                    variant="outlined"
                    label="Observaciones"
                    name="observaciones_usuario"
                    size="small"
                    fullWidth
                    multiline
                    maxRows={2}
                    value={datos.observaciones_usuario}
                    onChange={handleChanges}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => setOpenClose(!openclose)}>
                    <Badge
                      badgeContent={
                        cantArchivos.length !== 0 ? cantArchivos[0].total : "!"
                      }
                      color={cantArchivos.length !== 0 ? "primary" : "warning"}
                    >
                      {cantArchivos.length !== 0 ? (
                        <AddAPhotoIcon />
                      ) : (
                        <AddAPhotoOutlinedIcon />
                      )}
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item xs={2} marginBottom={"5px"}>
                <Button
                  disabled={boton}
                  variant="outlined"
                  onClick={(e) => handleAction(e)}
                >
                  {modo === "editar" ? "Guardar cambios" : "Enviar datos"}
                </Button>
                <Button
                  sx={{ marginLeft: "10px" }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => salir()}
                >
                  {"Volver"}
                </Button>
              </Grid>
            </Grid>
            <Typography variant="caption" sx={{ marginLeft: "10px" }}>
              Si el medicamento no esta en la lista solicitelo a RRHH
            </Typography>
            <br></br>
            <Typography variant="caption" sx={{ marginLeft: "10px" }}>
              No olvide adjuntar la receta (icono cámara)
            </Typography>
          </>
        ) : (
          <CircularProgress disableShrink color="secondary" />
        )}
      </Container>
      {openclose ? (
        <FileUploadMultiple fileId={datos.id_archivo} openclose={true} borrar={borrar} />
      ) : null}

      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
        message={mensaje}
      />
    </div>
  );
}
