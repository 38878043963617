import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useNavigate } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import Navbar from './Navbar';
import { useEffect, useState } from 'react'
import { UrlApi } from "../services/apirest";
import { DoneAll, DoneAllOutlined, Info, Payments, PlaylistAdd, PrintOutlined, Settings, StadiumTwoTone, ThumbUpAlt, VerifiedTwoTone, VerifiedUser } from '@mui/icons-material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';

export default function Menu() {
    let navigate = useNavigate();
    const [cambiapassword, setCambiaPassword] = useState('N')

    useEffect(() => {
        const VerificaPass = () => {
            fetch(UrlApi + '/userpass/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: localStorage.getItem("user"), password: "123456"
                })
            })
                .then(response => response.json())
                .then(data => setCambiaPassword(data.message));
        }
        VerificaPass()
    }, [])


    return (
        <div>
            <Navbar />
            <Container sx={{ marginTop: "55px" }}>
                {cambiapassword === 'N' ?
                    <Grid container spacing={1} direction="column" sx={{ width: isBrowser ? "400px" : null }}>
                        {localStorage.getItem("role") === 'usuario' ?
                            <>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/recetas/nuevo")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(140,180,140)",
                                                border: "2px solid green",
                                            }}> <h1><PlaylistAdd /></h1></Avatar>}
                                            title="Nueva"
                                            subheader="Solicitar nueva autorizacion"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/listarrecetas")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(210,180,140)",
                                                border: "2px solid blue",
                                            }} ><DoneAll /></Avatar>}
                                            title="Mis Recetas"
                                            subheader="Ver solicitudes y autorizadas"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card sx={{ border: '1px solid black', boxShadow: 10 }} >
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "red",
                                                border: "2px solid blue",
                                            }} ><Info /></Avatar>}
                                            title="Informacion"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                        <CardContent sx={{ paddingTop: 0 }}>
                                            <Typography variant='subtitle1'>
                                                Si el medicamento no se encuentra en la lista solicítelo a RRHH
                                                <br></br>
                                                No olvide adjuntar la receta (icono cámara)
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </> : null}
                        {localStorage.getItem("role") === 'admin' || localStorage.getItem("role") === 'medicina' ?
                            <>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/listaautorizacion/autorizar")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(60,190,60)",
                                                border: "2px solid navy",
                                            }} ><ThumbUpAlt sx={{ color: 'navy' }} /></Avatar>}
                                            title="Autorizar Recetas"
                                            subheader="Ver solicitudes/recetas para autorizar"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/catalogo")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(60,60,60)",
                                                border: "2px solid grey",
                                            }} ><MedicationLiquidIcon sx={{ color: 'white' }} /></Avatar>}
                                            title="Catalogo de medicamentos"
                                            subheader="Consulta del catalogo de medicamentos "
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                            </>
                            : null}
                        {localStorage.getItem("role") === 'admin' ?
                            <>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/listaautorizacion/controlar")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(30,30,30)",
                                                border: "2px solid orange",
                                            }} ><DoneAllOutlined sx={{ color: 'orange' }} /></Avatar>}
                                            title="Control Recetas"
                                            subheader="Control de recetas autorizadas"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/apagar")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(162,42,45)",
                                                border: "2px solid navy",
                                            }} ><Payments /></Avatar>}
                                            title="Pagos"
                                            subheader="Preparar pagos"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/listados")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(250,250,45)",
                                                border: "2px solid black",
                                                color: 'black'
                                            }} ><FormatListNumberedIcon /></Avatar>}
                                            title="Listado"
                                            subheader="Todas las gestiones"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/users")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(110,20,80)",
                                                border: "2px solid orange",
                                            }} ><VerifiedUser sx={{ color: 'orange' }} /></Avatar>}
                                            title="Gestion Usuarios"
                                            subheader="Alta , baja y modificaciones de usuarios"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/estadisticas")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(10,80,20)",
                                                border: "2px solid teal",
                                            }} ><StadiumTwoTone sx={{ color: 'white' }} /></Avatar>}
                                            title="Estadisticas"
                                            subheader="estadisticas generales"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/configs")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(10,20,80)",
                                                border: "2px solid teal",
                                            }} ><Settings sx={{ color: 'white' }} /></Avatar>}
                                            title="Configuraciones"
                                            subheader="Seteos del sistema"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                            </>
                            : <></>}
                        {localStorage.getItem("role") === 'farmacia' ?
                            <>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/farmacia")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(10,20,80)",
                                                border: "2px solid teal",
                                            }} ><VerifiedTwoTone sx={{ color: 'white' }} /></Avatar>}
                                            title="Verificar Token"
                                            subheader="Verificación de numero de Receta y Token"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={4}>
                                    <Card onClick={() => navigate("/farmacialistado")} sx={{ border: '1px solid black', boxShadow: 10, cursor: 'pointer' }}>
                                        <CardHeader
                                            avatar={<Avatar sx={{
                                                boxShadow: 5,
                                                backgroundColor: "rgb(100,200,80)",
                                                border: "2px solid teal",
                                            }} ><PrintOutlined sx={{ color: 'white' }} /></Avatar>}
                                            title="Listado de ventas"
                                            subheader="Listar recetas vendidas/entregadas por periodo"
                                            titleTypographyProps={{ variant: 'h5' }}
                                        />
                                    </Card>
                                </Grid>
                            </>
                            : <></>}
                    </Grid> :
                    <Grid container spacing={1} direction="column" sx={{ width: isBrowser ? "400px" : null, marginTop: '65px' }}>
                        <Card sx={{ border: "1px solid", boxShadow: 10, cursor: 'pointer' }}
                            onClick={() => navigate("/infouser")}>
                            <CardHeader
                                avatar={<Avatar sx={{
                                    boxShadow: 5,
                                    bgcolor: 'red',
                                    border: "2px solid red",
                                }} ><b>!</b></Avatar>}
                                title="Cambiar password"
                                subheader="Debe cambiar su contraseña por una nueva, haga click aqui"
                                titleTypographyProps={{ variant: 'h5' }}
                            />
                        </Card>
                    </Grid>
                }

            </Container>
        </div>
    )
}



