import Login from "./components/Login";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Private from "./components/Private";
import Listados from "./components/Listados";
import Menu from "./components/Menu";
import Recetas from "./components/Recetas";
import ListarRecetas from "./components/ListarRecetas";
import ListaAutorizacion from "./components/ListaAutorizacion";
import UserControl from "./components/users/UserControl";
import Usuarios from "./components/users/Usuarios";
import InfoUser from "./components/users/InfoUser";
import Apagar from "./components/Apagar";
import Config from "./components/config/Config";
import Catalogo from "./components/Catalogo";
import Farmacia from "./components/farmacia/Farmacia";
import FarmaciaListado from "./components/farmacia/FarmaciaListado";
import Stats from "./components/estadisticas/Stats";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/navbar" element={<Private Component={Navbar} />} />
        <Route path="/listados" element={<Private Component={Listados} />} />
        <Route
          path="/recetas/:modo"
          element={<Private Component={Recetas} />}
        />
        <Route path="/menu" element={<Private Component={Menu} />} />
        <Route
          path="/listarrecetas"
          element={<Private Component={ListarRecetas} />}
        />
        <Route
          path="/listaautorizacion/:modo"
          element={<Private Component={ListaAutorizacion} />}
        />
        <Route path="/users" element={<Private Component={Usuarios} />} />
        <Route
          path="/usercontrol/:accion"
          element={<Private Component={UserControl} />}
        />
        <Route path="/infouser" element={<Private Component={InfoUser} />} />
        <Route path="/apagar" element={<Private Component={Apagar} />} />
        <Route path="/configs" element={<Private Component={Config} />} />
        <Route path="/catalogo" element={<Private Component={Catalogo} />} />
        <Route path="/farmacia" element={<Private Component={Farmacia} />} />
        <Route
          path="/farmacialistado"
          element={<Private Component={FarmaciaListado} />}
        />
        <Route path="/estadisticas" element={<Private Component={Stats} />} />
      </Routes>
    </>
  );
}

export default App;
